import React, { useState, useEffect } from 'react';
import Header from '../Header/Header';
import Footer from '../Footer';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import ServerName from '../../config'
import { Helmet } from 'react-helmet';
import { useAuth } from '../Header/AuthContext';
function Profile() {

    const { setIsLoggedIn } = useAuth();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [old_password, setOldPassword] = useState('');
    const [new_password, setNewPassword] = useState('');
    const [confirm_password, setConfirmPassword] = useState('');
    const [Birthdate, setBirthdate] = useState('');
    const [showPasswordFields, setShowPasswordFields] = useState(false);
    const [passwordsMatch, setPasswordsMatch] = useState(true);
    const [submitted, setSubmitted] = useState(false);
    const [oldPasswordValid, setOldPasswordValid] = useState(true); // Track old password validation result

    const navigate = useNavigate();
    const token = localStorage.getItem('token');

    useEffect(() => {
        // Check if new_password and confirm_password match
        setPasswordsMatch(new_password === confirm_password);
    }, [new_password, confirm_password]);


    /* ***********************Get User Api Call**********************/
    const fetchProfile = async () => {
        if (!token) {
            return;
        }
        try {
            const response = await axios.get(ServerName + '/get_user', {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            // Set response Data of user to get in UI
            setName(response.data.data.name);
            setEmail(response.data.data.email_address);
            setBirthdate(response.data.data.birthdate);
        } catch (error) {
            if (axios.isAxiosError(error) && error.response?.status === 401) {
                // Unauthorized: Token might have already expired or is invalid
                localStorage.removeItem('token');
                setIsLoggedIn(false);
                navigate('/');
            } else {
                console.error('Error fetching data:', error);
                // You can set an error state here if needed
            }
        }
    };

    useEffect(() => {
        fetchProfile();
    }, [token]);

    /* *********************** When User Submit Profile**********************/

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSubmitted(true);
        // After updating profile user send to backed updated data
        const updatedData = {
            name: name,
            email: email,
            birthdate: Birthdate,
            email_address: email,
            old_password: old_password,
            new_password: new_password,
        };
        if (passwordsMatch) {
            try {
                const response = await axios.post(ServerName + '/update_profile', updatedData, {
                    headers: {
                        Authorization: `Bearer ${token}`, // Include the token in the headers
                    },
                });

                if (response.data.success) {
                    setOldPasswordValid(true);
                    toast.success('Profile updated successfully.', {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 2000,
                    });
                    // await new Promise((resolve) => setTimeout(resolve, 2000));
                    // navigate('/');
                } else {
                    setOldPasswordValid(false);
                }
                setOldPassword('');
                setNewPassword('');
                setConfirmPassword('');
            } catch (error) {
                setOldPasswordValid(false);
                toast.error('An error occurred while updating profile.', {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
            }
        };
    }
    return (
        <>
            <Helmet>
                <title>Profile Page</title>
                <meta name="title" content="Profile page" />
                <meta name="description" content='Profile info, Get all info of user ' />
                <meta name="keyword" content="Profile user, info user" />
                <meta property='og:url' content={`${ServerName}/profile`} />
                <meta property='og:title' content="Profile page" />
                <meta property='og:description' content="Get all info of profile, user info" />
                <meta property='og:image' content={`${ServerName}/logo192.png`} />
                <link rel="canonical" href={`${ServerName}/profile`} />
            </Helmet>
            <Header />
            <div className='mx-auto  '>
                <div className='sign d-flex w-100 align-item-center justify-content-center'>
                    <div className='bg-white sign-right'>
                        <div className='sign-form animated-form '>
                            <div className="text-center">
                                <h4 className="mt-5 mb-3 pb-1"> Profile</h4>
                            </div>
                            <form onSubmit={handleSubmit}>
                                <div className='sign-email'>
                                    <label htmlFor='text'>Enter your name: </label>
                                    <input type='text' name='name' placeholder='example@gmail.com' value={name} onChange={(e) => setName(e.target.value)} />
                                </div>
                                <div className='sign-email'>
                                    <label htmlFor='text'>Enter your Birthdate: </label>
                                    <input
                                        className='text-start dir-ltr'
                                        type='date'
                                        name='birthdate'
                                        placeholder='example@gmail.com'
                                        value={Birthdate}
                                        onChange={(e) => setBirthdate(e.target.value)}
                                        max={new Date().toISOString().split('T')[0]}
                                    />
                                </div>
                                <div className='sign-email'>
                                    <label htmlFor='email'>Email: </label>
                                    <input
                                        type='email'
                                        name='email'
                                        placeholder='example@gmail.com'
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        disabled />
                                </div>
                                <div className='d-flex justify-content-between remember-forgot'>
                                    <span className='mt-2'>
                                        <a className='text-decoration-none d-flex mb-3' >
                                            <input
                                                type="checkbox"
                                                className='chekbox me-2'
                                                onChange={() => setShowPasswordFields(!showPasswordFields)}
                                            />Change password ?</a>
                                    </span>
                                </div>

                                {showPasswordFields && (
                                    <>
                                        <div className='sign-pass'>
                                            <label htmlFor='passw'>Old password: </label>
                                            <input type='password' name='passw' value={old_password} onChange={(e) => setOldPassword(e.target.value)} />
                                            {!oldPasswordValid && submitted && (
                                                <span className='text-danger' style={{ fontSize: '16px' }}>The old password does not match our records</span>
                                            )}
                                        </div>
                                        <div className='sign-pass'>
                                            <label htmlFor='passw'>New password: </label>
                                            <input type='password' name='passw' value={new_password} onChange={(e) => setNewPassword(e.target.value)} />
                                        </div>
                                        <div className='sign-pass'>
                                            <label htmlFor='passw'> Confirm password: </label>
                                            <input type='password' name='passw' value={confirm_password} onChange={(e) => setConfirmPassword(e.target.value)} />
                                            {!passwordsMatch && submitted && (
                                                <span className='text-danger' style={{ fontSize: '16px' }}>New Password does not match Confirm Password</span>
                                            )}
                                        </div>
                                    </>
                                )}
                                <button className='sign-btn' type='submit'  >Submit<ToastContainer /></button>
                                <div className='singn-up-width text-center mt-2'>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Profile
