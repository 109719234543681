import React, { useState, useEffect } from 'react';
import '../assets/scss/Event.scss'
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../assets/scss/_varibles.scss'
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';
import ServerName from '../config';
import { useNavigate, useLocation } from 'react-router-dom';
import { fetchByCategories } from './Events/ApiUtil'; // Import the functions  
import Footer from './Footer';
import Header from './Header/Header';
import EventComponent from './Events/EventComponent';
import { Helmet } from 'react-helmet';
export default function Search() {

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const searchTerm = searchParams.get('query');
  const [events, setEvents] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState('all');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [showAllEvents, setShowAllEvents] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [options, setOptions] = useState([]);
  const [locationId, setLocationId] = useState('');
  const [locationName, setLocationName] = useState('');
  const [initialRender, setInitialRender] = useState(true); 

  const navigate = useNavigate();



  const handleLocationChange = async (event) => {
    const { value } = event.target;
    if (value) {
      setLocationId(value);
      } else {
        setLocationId('')
      }
    };

    // useEffect(()=>{
    //   if (locationId !== undefined && locationId !== null) {
    //     if(selectedCategories == 'all'){
    //       handleFilterChange(selectedFilter,'all');
    //     }else{
    //       handleFilterChange(selectedFilter);
    //     }
    //   }
    // },[locationId]);

    useEffect(() => {
      setInitialRender(false);
  }, [initialRender]);

  useEffect(() => {
    if (!initialRender) { // Check if it's not the initial render
      if (locationId !== undefined && locationId !== null) {
        if (selectedCategories == 'all') {
          handleFilterChange(selectedFilter, 'all');
        } else {
          handleFilterChange(selectedFilter);
        }
      }
    } 
  }, [locationId]);

  /* *****************  FetchBySearchTerm  Api Call ***************/

  const FetchBySearchTerm = async (searchTerm, selectedDate) => {

    try {
      if (selectedCategories.includes('all')) {
        // alert("if") 
        const response = await axios.get(ServerName + '/get_home_page_events_list', {
          params: {
            search: searchTerm,
            filter_by_date: selectedDate,
            filter_by_day: selectedFilter,
            location_id: locationId
          },
        });
        return response.data.data;
      } else {
        // alert("else")
        const response = await axios.get(ServerName + '/get_home_page_events_list', {
          params: {
            search: searchTerm,
            category_id: selectedCategories.join(','), // Join the array of selected categories
            filter_by_date: selectedDate, // Pass the selected date\
            filter_by_day: selectedFilter,
            location_id: locationId
          },
        });
        return response.data.data;
      }

    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  };


  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        // if (searchTerm  ) {
        const eventsData = await FetchBySearchTerm(searchTerm, selectedDate);
        setEvents(eventsData);
        setSelectedDate(selectedDate)
        // }
        setLoading(false);
      } catch (error) {
        setError('Error fetching data. Please try again later.');
        setLoading(false);
      }
    };
    fetchData();
  }, [searchTerm]);


  /******************  toggleFilter ***************/

  const toggleFilter = (filter) => {
    if (selectedFilter === filter) {
      handleFilterChange(null); // Deselect the current filter
    } else {
      handleFilterChange(filter); // Select a new filter
    }
  };


  /* *****************  handleFilterChange ,Changes in filter***************/

  const handleFilterChange = async (filter, category_id) => {
    setLoading(true);
    try {
      let updatedSelectedCategories = [...selectedCategories];
      if (category_id === 'all') {
        updatedSelectedCategories = ['all']; // Always keep 'all' as selected
      } else {
        if (selectedCategories.includes('all') && selectedFilter === filter) {
          updatedSelectedCategories = [category_id]; // Clear 'all' and select the new category
        } else {
          if (updatedSelectedCategories.includes(category_id)) {
            updatedSelectedCategories = updatedSelectedCategories.filter(id => id !== category_id);
          } else {
            if (category_id) {
              updatedSelectedCategories.push(category_id);
            }
          }
        }
      }

      if (filter === 'today' || filter === 'week' || filter === 'weekend' || filter === 'month') {
        const dateInput = document.getElementById('dateInput');
        if (dateInput) {
          dateInput.value = '';
        }
        setSelectedDate(null);
      }

      setSelectedCategories(updatedSelectedCategories);
      if (updatedSelectedCategories.length === 0) {
        setSelectedCategories(['all']); // If no categories are selected, select 'all'
      }

      const categoryQueryString = updatedSelectedCategories.includes('all') ? '' : updatedSelectedCategories.join(',');
      // const url = ServerName + `/api/web/get_events_list?search=${searchTerm}&filter_by_day=${filter}&category_id=${categoryQueryString}`;
      const searchTermToUse = searchTerm || '';
      const selectedDateForParams = selectedFilter === filter ? selectedDate : null;
      // const endpoint = `/api/web/get_home_page_events_list?search=${searchTerm}`;
      const endpoint = ServerName + `/get_home_page_events_list?search=${searchTermToUse}`;
      const params = {
        filter_by_date: selectedDateForParams,
        category_id: categoryQueryString, // Add the selected category filter
        filter_by_day: filter,
        location_id: locationId
      };

      const response = await axios.get(endpoint, { params });

      // const response = await axios.get(url);

      setEvents(response.data.data);
      setSelectedFilter(filter);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };



  /* *****************  FetchByDate ***************/

  const FetchByDate = async (selectedDate, searchTerm) => {
    try {
      const response = await axios.get(ServerName + '/get_home_page_events_list', {
        params: {
          filter_by_date: selectedDate,
          search: searchTerm,
          category_id: selectedCategories.includes('all') ? '' : selectedCategories.join(','), // Add category filter
          location_id: locationId
        },
      });
      return response.data.data;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  };

  /* *****************  handleDateChange, when Date Changes ***************/


  // const handleDateChange = async (event) => {
  //   const selectedDate = event.target.value;
  //   try {
  //     setLoading(true);
  //     setSelectedFilter('');
  //     // if (searchTerm) {
  //       const data = await FetchByDate(selectedDate, searchTerm);
  //       setEvents(data);
  //       setSelectedDate(selectedDate)
  //     // } else {
  //       // setEvents([]);
  //     // }
  //     setLoading(false);
  //   } catch (error) {
  //     console.error('Error fetching data:', error);
  //     setLoading(false);
  //   }
  // };

  const onDateChange = async (date) => {
    try {
      setLoading(true);
      setSelectedFilter('');

      if (date) {
        let selectedDate = date;

        if (!(date instanceof Date)) {
          // If date is not a Date object, try creating one
          selectedDate = new Date(date);
        }

        if (isNaN(selectedDate.getTime())) {
          // Handle the case when the date is invalid
          console.error('Invalid date:', date);
          return;
        }

        const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
        const formattedDate = selectedDate.toLocaleDateString('en-US', options).replace(/\//g, '-');

        const data = await FetchByDate(formattedDate, searchTerm,locationId);
        setEvents(data);
        setSelectedDate(formattedDate);
      } else {
        // Handle the case when the date is cleared
        const data = await FetchByDate(null, searchTerm,locationId);
        setEvents(data);
        setSelectedDate(null);
      }

      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };


  useEffect(() => {
    setSelectedCategories(['all']); // Set 'all' category as initially selected
    // handleFilterChange(selectedFilter, 'all'); // Apply the selected filter and 'all' category
  }, []);


  /* *****************  fetchByCategories Api Call ***************/

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const [categoriesData] = await Promise.all([fetchByCategories()]);
        setCategories(categoriesData);
        setShowAllEvents(true);
        setLoading(false);
      } catch (error) {
        setError('Error fetching data. Please try again later.');
        setLoading(false);
      }
    };
    fetchData();
  }, []);


  // Navigate when event Clicked
  const handleEventClick = (eventId) => {
    navigate(`/event-details/${eventId}`);
  };

    /***********************fetchLocation htmlFor Select Dropdown*****************************/
    const fetchLocation = async () => {
      try {
        const response = await axios.get(ServerName + '/get_filter_locations_list');
        const locationResponse = response.data.data;
        const formattedOptions = locationResponse.map((location) => ({
          value: location.id,
          label: `${location.name}`,
        }));
        setOptions([{ value: '', label: 'Select Location' }, ...formattedOptions]);
      } catch (error) {
        console.error('Error fetching locations:', error);
      }
    };
  
    useEffect(() => {
      fetchLocation();
    }, []);
  return (
    <>
      <Helmet>
        <title>Search Page</title>
        <meta name="title" content="Search page" />
        <meta name="description" content='search events, filter events' />
        <meta name="keyword" content="Search events, searching events" />
        <meta property='og:url' content={`${ServerName}/search?query=`} />
        <meta property='og:title' content="Search page" />
        <meta property='og:description' content="Search events, searching events" />
        <meta property='og:image' content={`${ServerName}/logo192.png`} />
        <link rel="canonical" href={`${ServerName}/search?query=`} />
      </Helmet>
      <Header />
      <EventComponent
        showAllEvents={showAllEvents}
        categories={categories}
        events={events}
        onDateChange={onDateChange}
        selectedFilter={selectedFilter}
        toggleFilter={toggleFilter}
        selectedCategories={selectedCategories}
        handleFilterChange={handleFilterChange}
        handleEventClick={handleEventClick}
        loading={loading}
        setLocationId={setLocationId}
        setLocationName={setLocationName}
        options={options}
        locationId={locationId}
        handleLocationChange={handleLocationChange}
      />
      <Footer />
    </>
  )
}