import React from 'react';
import Header from './Header/Header'
import Slider from './Slider';
import SmallCard from './Events/SmallCard/SmallCard';
import Feature from './Feature';
import AdSlider from './AdSlider';
import Footer from './Footer';
import { SmallCardProvider } from './Events/SmallCard/SmallCardContext';
import { Helmet } from 'react-helmet';
import ServerName from '../config';
// Home Page 
function Home() {
  return (
    <>
    <Helmet>
      <title>Home Page</title>
      <meta name="title" content="Home page"/>
      <meta name="description" content='Get all info of events & venues'/>
      <meta name="keyword" content="Home events, search events, event venues"/>
      <meta property="og:type" content="website" />
      <meta property='og:url' content={`${ServerName}`}/>
      <meta property='og:title' content="Home page" />
      <meta property='og:description' content="Get all info of events, explore events" />
      <meta property='og:image' content={`${ServerName}/logo192.png`}/>
      <link rel="canonical" href={`${ServerName}`} />
    </Helmet>
    <SmallCardProvider>
      <Header/>
      <Slider />
      <SmallCard/>
      <Feature />
      <AdSlider />
      <Footer />
    </SmallCardProvider>
    </>
  )
}
export default Home