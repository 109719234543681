import React from 'react'
import '../assets/scss/AdSlider.scss'


import SponcerSlider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';



import sponcer_img1 from '../assets/img/sponcer_img1.png'
import sponcer_img2 from '../assets/img/sponcer_img2.png'
import sponcer_img3 from '../assets/img/sponcer_img3.png'
import sponcer_img4 from '../assets/img/sponcer_img4.png'
import sponcer_img5 from '../assets/img/sponcer_img5.png'
import sponcer_img6 from '../assets/img/sponcer_img1.png'

export default function AdSlider() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    // centerMode: true,
    // centerPadding:'10px',
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className='sponcer_section'>
      <div className='container'>
        <SponcerSlider  {...settings} className='sponcer_slider '>
          <div className='sponcer_img'>
              <img src={sponcer_img1} alt='sponcer_img1' className='mx-auto'/>
          </div>
          <div className='sponcer_img'>
              <img src={sponcer_img2} alt='sponcer_img2' className='mx-auto'/>
          </div>
          <div className='sponcer_img'>
              <img src={sponcer_img3} alt='sponcer_img3'className='mx-auto'/>
          </div>
          <div className='sponcer_img'>
              <img src={sponcer_img4}  alt='sponcer_img4' className='mx-auto'/>
          </div>
          <div className='sponcer_img'>
              <img src={sponcer_img5} alt='sponcer_img5' className='mx-auto'/>
          </div>
          <div className='sponcer_img'>
              <img src={sponcer_img6} alt='sponcer_img6' className='mx-auto'/>
          </div>
        </SponcerSlider>
      </div>
    </div>
  )
}
