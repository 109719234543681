import React, { useState, useEffect } from "react";
import Header from "../Header/Header";
import Footer from "../Footer";
import logo from "../../assets/img/logo192.png";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import ServerName from "../../config";
import { Helmet } from "react-helmet";
const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Regex for Email

function CreatorSingup() {
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [company_name, setCompanyName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirm_password, setConfirmPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState(true);

  useEffect(() => {
    // Check if new_password and confirm_password match
    setPasswordsMatch(password === confirm_password);
  }, [password, confirm_password]);


  /* ***********************  Create Request  Api Call **********************/
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      first_name === "" ||
      last_name === "" ||
      email === "" ||
      password === "" ||
      confirm_password === ""
    ) {
      // Display error messages
      setFormSubmitted(true);
      return;
    }
    if (!emailRegex.test(email)) {
      setFormSubmitted(true);
      setEmailError("Enter a valid Email Address");
      return;
    }
    setEmailError("");
    // send Data (items) to backend After Registration by user
    const items = {
      first_name: first_name,
      last_name: last_name,
      company_name: company_name,
      email_address: email,
      password: password,
      confirm_password: confirm_password,
    };
    if (passwordsMatch) {
      try {
        const response = await axios.post(
          ServerName + "/web_sign_up_creator",
          items
        );
        if (response.data.success) {
          const token = response.data.token;
          axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
          const successMessage = "Creator request sent successfully! Our team will review it.";
          toast.success(successMessage, {
            // position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
            style: {
              width: '500px',
              top: "150px",
              right: "250px"
            },
          });
          setEmail(""); // clear Input field values When user Send Request 
          setPassword("");
          setFirstName("");
          setLastName("");
          setCompanyName("");
          setConfirmPassword("");
          setFormSubmitted(false);
        } else {
          toast.error(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
        }

      } catch (error) {
        toast.error("An error occurred.", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>Creator Page</title>
        <meta name="title" content="Creator  page" />
        <meta name="description" content='user can create Admin account , create events ' />
        <meta name="keyword" content="creator account , Creator" />
        <meta property='og:url' content={`${ServerName}/creator`} />
        <meta property='og:title' content="Sign-in  page" />
        <meta property='og:description' content="user can create Admin account , create events " />
        <meta property='og:image' content={`${ServerName}/logo192.png`} />
        <link rel="canonical" href={`${ServerName}/creator`} />
      </Helmet>
      <Header />
      <div className="mx-auto ">
        <div className="sign d-flex w-100">
          <div className=" text-center sign-left d-flex flex-column align-items-center justify-content-center">
            <h3 className="login-title mt-5"> Hey There! Welcome 405 </h3>
            <div className="login-logo">
              <img src={logo} alt="logo" />
            </div>
          </div>
          <div className="bg-white sign-right">
            <div className="sign-form animated-form ">
              <div className="text-center">
                <h4 className="mt-5 mb-3 pb-1"> Sign Up as a Event Creator</h4>
              </div>
              <form onSubmit={handleSubmit}>
                <div className="sign-email">
                  <label htmlFor="text">Enter your First Name<span style={{ color: 'red' }}>*</span> : </label>
                  <input
                    type="text"
                    name="name"
                    placeholder="example@gmail.com"
                    value={first_name}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                  {formSubmitted && first_name === "" && (
                    <span className="error-message">Enter First Name :</span>
                  )}
                </div>
                <div className="sign-email">
                  <label htmlFor="text">Enter your Last Name<span style={{ color: 'red' }}>*</span> : </label>
                  <input
                    type="text"
                    name="name"
                    placeholder="example@gmail.com"
                    value={last_name}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                  {formSubmitted && last_name === "" && (
                    <span className="error-message">Enter Last Name :</span>
                  )}
                </div>
                <div className="sign-email">
                  <label htmlFor="text">Enter your Company Name : </label>
                  <input
                    type="text"
                    name="name"
                    value={company_name}
                    onChange={(e) => setCompanyName(e.target.value)}
                  />
                  {/* {formSubmitted && company_name === "" && (
                    <span className="error-message">Enter Company Name</span>
                  )} */}
                </div>
                <div className="sign-email">
                  <label htmlFor="email">Email<span style={{ color: 'red' }}>*</span> : </label>
                  <input
                    type="email"
                    name="email"
                    placeholder="example@gmail.com"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  {formSubmitted && email === "" && (
                    <span className="error-message">
                      Enter a Valid Email Address :
                    </span>
                  )}
                  {formSubmitted && email !== "" && emailError && (
                    <span className="error-message">{emailError}</span>
                  )}
                </div>
                <div className="sign-pass">
                  <label htmlFor="passw">Password<span style={{ color: 'red' }}>*</span> : </label>
                  <input
                    type="password"
                    name="passw"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  {formSubmitted && password === "" && (
                    <span className="error-message">Enter Password :</span>
                  )}
                </div>
                <div className="sign-pass">
                  <label htmlFor="passw">Confirm Password<span style={{ color: 'red' }}>*</span> : </label>
                  <input
                    type="password"
                    name="cnfpassw"
                    value={confirm_password}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                  {formSubmitted && confirm_password === "" && (
                    <span className="error-message">
                      Enter Confirm Password :
                    </span>
                  )}
                  {!passwordsMatch &&
                    password !== confirm_password &&
                    confirm_password !== "" && (
                      <span
                        className="error-message"
                        style={{ fontSize: "14px" }}
                      >
                        Password does not match Confirm Password :
                      </span>
                    )}
                </div>
                <button className="sign-btn" type="submit">
                  Send Request <ToastContainer />
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default CreatorSingup;
