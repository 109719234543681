import React, { useState, useEffect } from "react";
import axios from "axios";
import "../assets/scss/Slider.scss";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Loading from "./Loading";
import { NavLink } from "react-router-dom";
import { containerStyle } from "./ContainerStyle";
import ServerName from "../config";

const getFirstNWords = (text, n) => {
  const words = text.split(" ");
  return words.slice(0, n).join(" ");
};

const removeHtmlTags = (html) => {
  var doc = new DOMParser().parseFromString(html, 'text/html');
  return doc.body.textContent || "";
};

const SlickSlider = () => {
  const [slides, setSlides] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);

  // (Slider) Get Featured Event List Api Call
  useEffect(() => {
    setLoading(true);
    axios
      .get(ServerName + "/get_featured_events_list")
      .then((response) => {
        setSlides(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  }, []);

  const handleSlideChange = (index) => {
    setCurrentSlide(index);
  };

  return (
    <div className="banner">
      <div className="container">
        <div className="banner-slider">
          {loading ? (
            <div style={containerStyle}>
              <Loading />
            </div>
          ) : (
            slides.length > 0 && (
              <Carousel
                showThumbs={false}
                showStatus={false}
                autoPlay={true}
                infiniteLoop={true}
                interval={3000}
                transitionTime={500}
                showArrows={false}
                showIndicators={true}
                selectedItem={currentSlide}
                onChange={handleSlideChange}
                swipeable={true} // Enable cursor slide control
              >
                {slides.map((slide, index) => {

                  // const numWordsInTitle = slide.title.split(" ").length;
                  // const numWordsToShow = numWordsInTitle > 3 ? 10 : 20;
                  // const truncatedDescription = getFirstNWords(
                  //   slide.description,
                  //   numWordsToShow
                  // );
                  // const ellipsis = truncatedDescription !== slide.description ? '....' : '';
                  // const displayDescription = truncatedDescription + ellipsis;

                  const numWordsToShow = slide.title.split(' ').length > 2 ? 10 : 20;
                  const descriptionText = removeHtmlTags(slide.description); // Remove HTML tags
                  return (
                    <div className="banner-slide position-relative" key={index}>
                      <img src={slide.large_image} alt="slideImage" />
                      <div className="banner-details">
                        <h1 className="banner-title">{slide.title}</h1>
                        {/* <p
                          className="banner_text"
                          dangerouslySetInnerHTML={{
                            __html: displayDescription,
                          }}
                        ></p> */}
                        {/* <p className='banner_text'>{getFirstNWords(slide.description, numWordsToShow)}....</p> */}
                        <p className='banner_text'>{getFirstNWords(descriptionText, numWordsToShow)}....</p>
                        <div className="pt-4">
                          <NavLink
                            className="banner-btn"
                            to={`/event-details/${slide.id}`}
                          >
                            View More
                          </NavLink>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </Carousel>
            )
          )}
          {slides.length > 0 && (
            <div className="dots">
              {slides.map((_, index) => (
                <div
                  key={index}
                  className={`dot ${index === currentSlide ? "active" : ""}`}
                  onClick={() => setCurrentSlide(index)}
                />
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SlickSlider;
