import React, { useState, useEffect } from 'react';
import '../../../assets/scss/SmallCard.scss'
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../../../assets/scss/_varibles.scss'
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';
import ServerName from '../../../config';
import { EventBtn } from '../../ContainerStyle';
import { useNavigate } from 'react-router-dom';
import { useSmallCard } from './SmallCardContext';
import EventComponent from '../EventComponent';
import { handleDateChange, fetchByCategories, fetchAllEvents } from '../ApiUtil'; // Import the functions

export default function SmallCard() {

  const { events, setEvents } = useSmallCard();
  const [showAllEvents, setShowAllEvents] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState('');
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedCategories, setSelectedCategories] = useState(['all']);
  const [options, setOptions] = useState([]);
  const [locationId, setLocationId] = useState('');
  const [locationName, setLocationName] = useState('');
  const [error, setError] = useState(null);
  const [initialRender, setInitialRender] = useState(true); 
  const navigate = useNavigate();



  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const [categoriesData, eventsData] = await Promise.all([fetchByCategories(), fetchAllEvents()]);
        setShowAllEvents(true);
        setCategories(categoriesData);
        setEvents(eventsData);
        setLoading(false);
      } catch (error) {
        setError('Error fetching data. Please try again later.');
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  /* ***************** toggleFilter ***************/

  const toggleFilter = (filter) => {
    if (selectedFilter === filter) {
      handleFilterChange(null); // Deselect the current filter
      const datePickerInput = document.getElementById('dateInput');
      if (datePickerInput) {
        datePickerInput.value = ''; // Clear the input value
      }
      // Update the selectedDate state
      setSelectedDate(null);
    } else {
      handleFilterChange(filter); // Select a new filter
    }
  };


  const handleLocationChange = async (event) => {
    const { value } = event.target;
    if (value) {
      setLocationId(value);
      } else {
        setLocationId('')
      }
    };
  

  // useEffect(()=>{
  //   if (locationId !== undefined && locationId !== null) {
  //     if(selectedCategories == 'all'){
  //       handleFilterChange(selectedFilter,'all');
  //     }else{
  //       handleFilterChange(selectedFilter);
  //     }
  //   }
  // },[locationId]);

  useEffect(() => {
      setInitialRender(false);
  }, [initialRender]);

  useEffect(() => {
    if (!initialRender) { // Check if it's not the initial render
      if (locationId !== undefined && locationId !== null) {
        if (selectedCategories == 'all') {
          handleFilterChange(selectedFilter, 'all');
        } else {
          handleFilterChange(selectedFilter);
        }
      }
    } 
  }, [locationId]);


  /* ***************** handleFilterChange when user change filter ***************/

  const handleFilterChange = async (filter, category_id) => {
    setLoading(true);
    try {
      let updatedSelectedCategories = [...selectedCategories];
      if (category_id === 'all') {
        updatedSelectedCategories = ['all']; // Always keep 'all' as selected
      } else {
        if (selectedCategories.includes('all') && selectedFilter === filter) {
          updatedSelectedCategories = [category_id];
        } else {
          if (updatedSelectedCategories.includes(category_id)) {
            updatedSelectedCategories = updatedSelectedCategories.filter(id => id !== category_id);
          } else {
            if (category_id) {
              updatedSelectedCategories.push(category_id);
            }
          }
        }
      }

      if (filter === 'today' || filter === 'week' || filter === 'weekend' || filter === 'month') {
        const dateInput = document.getElementById('dateInput');
        if (dateInput) {
          dateInput.value = '';
        }
        setSelectedDate(null);
      }
      setSelectedCategories(updatedSelectedCategories);
      if (updatedSelectedCategories.length === 0) {
        setSelectedCategories(['all']); // If no categories are selected, select 'all'
      }

      const categoryQueryString = updatedSelectedCategories.includes('all') ? '' : updatedSelectedCategories.join(',');
      // const selectedDateForParams = category_id === 'all' ? null : selectedFilter === filter ? selectedDate : null;
      const selectedDateForParams = selectedFilter === filter ? selectedDate : null;
      const endpoint = ServerName + '/get_home_page_events_list';

      const params = {
        filter_by_date: selectedDateForParams,
        category_id: categoryQueryString, // Add the selected category filter
        filter_by_day: filter,
        location_id: locationId
      };

      const response = await axios.get(endpoint, { params });

      setEvents(response.data.data);
      setSelectedFilter(filter);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };


  useEffect(() => {
    setSelectedCategories(['all']); // Set 'all' category as initially selected
    // handleFilterChange(selectedFilter, 'all'); // Apply the selected filter and 'all' category
  }, []);



  // const onDateChange = (event) => {
  //   const selectedDate = event.target.value;
  //   handleDateChange(selectedDate, setSelectedFilter, selectedCategories, setEvents, setSelectedDate, setLoading);
  // };

  const onDateChange = (date) => {
    if (date) {
      let selectedDate = date;
      if (!(date instanceof Date)) {
        // If date is not a Date object, try creating one
        selectedDate = new Date(date);
      }
      if (isNaN(selectedDate.getTime())) {
        // Handle the case when the date is invalid
        console.error('Invalid date:', date);
        return;
      }
      const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
      const formattedDate = selectedDate.toLocaleDateString('en-US', options).replace(/\//g, '-');
      handleDateChange(formattedDate, setSelectedFilter, 
        selectedCategories, setEvents,
         setSelectedDate, setLoading,
         locationId
         );
    } else {
      // Handle the case when the date is cleared
      handleDateChange('', setSelectedFilter, selectedCategories, setEvents, setSelectedDate, setLoading, locationId);
    }
  };

  /******************* Navigate to Event Details Page *****************************/

  const handleEventClick = (eventId) => {
    navigate(`/event-details/${eventId}`);
  };

  /************************Navigate to Event Page************************************/
  const handleButtonClick = () => {
    navigate('/events');
  };


  /***********************fetchLocation htmlFor Select Dropdown*****************************/
  const fetchLocation = async () => {
    try {
      const response = await axios.get(ServerName + '/get_filter_locations_list');
      const locationResponse = response.data.data;
      const formattedOptions = locationResponse.map((location) => ({
        value: location.id,
        label: `${location.name}`,
      }));
      setOptions([{ value: '', label: 'Select Location' }, ...formattedOptions]);
    } catch (error) {
      console.error('Error fetching locations:', error);
    }
  };

  useEffect(() => {
    fetchLocation();
  }, []);



  return (
    <>

      <EventComponent
        onDateChange={onDateChange}
        selectedFilter={selectedFilter}
        toggleFilter={toggleFilter}
        showAllEvents={showAllEvents}
        selectedCategories={selectedCategories}
        handleFilterChange={handleFilterChange}
        loading={loading}
        events={events}
        handleEventClick={handleEventClick}
        categories={categories}
        setLocationId={setLocationId}
        setLocationName={setLocationName}
        options={options}
        locationId={locationId}
        handleLocationChange={handleLocationChange}
      />


      <EventBtn className='d-flex justify-content-center position-relative mb-5'>
        <a className='eventbtn text-decoration-none' onClick={handleButtonClick}> All Events</a>
      </EventBtn>
    </>
  )
}


