import React, { useEffect, useState } from 'react';
import Header from './Header/Header';
import Footer from './Footer';
import axios from 'axios';
import { SmallCardAfter, containerStyle } from './ContainerStyle';
import Loading from './Loading';
import ServerName from '../config';
import { Helmet } from 'react-helmet';
function PrivacyPolicy() {

  const [privacyContent, setPrivacyContent] = useState('');
  const [loading, setLoading] = useState(true);


/* *****************  fetchPrivacy  Api Call ***************/

  const fetchPrivacy = async () => {
    try {
      const response = await axios.get(ServerName+'/get_privacy_policy');
      setPrivacyContent(response.data.data);
      setLoading(false); // Set loading to false after data is fetched
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false); // Also set loading to false in case of error
      throw error;
    }
  };

  useEffect(() => {
    fetchPrivacy();
  }, []);

  // Paragraph Spliting
  const paragraphs = privacyContent.split('<p></p>');

  return (
    <>
     <Helmet>
     <title>Privacy Page</title>
      <meta name="title" content="Privacy page"/>
      <meta name="description" content='privacy policy of events and web application'/>
      <meta name="keyword" content="Privacy-policy"/>
      <meta property='og:url' content={`${ServerName}/privacy-policy`}/>
      <meta property='og:title' content="Privacy page" />
      <meta property='og:description' content="Get all info of events, privacy policy of events and webapplication" />
      <meta property='og:image' content={`${ServerName}/logo192.png`}/>
      <link rel="canonical" href={`${ServerName}/privacy-policy`} />
      </Helmet>
      <Header/>
      <div className='container'>
        <div className="privacy-content mt-5">            
          <SmallCardAfter className='section-title position-relative col-xxl-2 col-xl-12'>
            <h2 className='ps-3 mb-5'>Privacy Policy</h2>
          </SmallCardAfter>
          {loading ? <div style={containerStyle}><Loading /></div> : (
            paragraphs.map((paragraph, index) => (
              <div key={index} className="paragraph" dangerouslySetInnerHTML={{ __html: paragraph }} />
            ))
          )}
        </div>
      </div>
      <Footer/>
    </>
  );
}

export default PrivacyPolicy;
