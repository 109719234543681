import React, {useState} from 'react'
import Header from '../Header/Header';
import Footer from '../Footer';
import '../../assets/scss/Sign.scss';
import logo from '../../assets/img/logo192.png';
import ServerName from '../../config';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';

function ForgotPassword() {

  const [email, setEmail] = useState('');
  const [error, setError] = useState(null);

 const navigate = useNavigate();

/* *********************** Forgot Password Api Call**********************/

  const handleSubmit = async (e) => {
    e.preventDefault();
    const items = {
      email: email,
    };
    try {
      const response = await axios.post(ServerName+'/forgot_password', items);
      if (response.data.success) {
        console.log("Sendind Otp for Otp Verification",response.data);
        navigate('/otp-verify',{ state: { email } }); // sent (email) forgot password to otp-verify page
      } else {
        toast.error(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
      }
      setEmail('');
    } catch (error) {
    
    }
  };

  return (
    <>
      <Header />
      <div className='mx-auto '>
        <div className='sign d-flex w-100'>
          <div className=' text-center sign-left forgot-pass d-flex flex-column align-items-center justify-content-center'>
            <h3 className='login-title mt-5'> Hey There! Welcome 405 </h3>
            <div className='login-logo'>
              <img src={logo} alt='logo'/>
            </div>
          </div>
          <div className='bg-white sign-right forgot-pass'>
            <div className='sign-form animated-form '>
              <div className="text-center">
                <h4 className="mt-5 mb-3 pb-1"> Forgot Password?</h4>
              </div>
              <form onSubmit={handleSubmit}>
                <div className='sign-email'>
                  <label htmlFor='email'>Enter your email: </label>
                  <input type='email' 
                  name='email' 
                  placeholder='example@gmail.com' 
                  value= {email} 
                  onChange={(e) => setEmail(e.target.value)}
                   />
                </div>
                <button className='sign-btn' type='submit'>Send Code<ToastContainer/></button>
              </form>
            </div>
          </div>
        </div>
      </div>
      
      <Footer />
    </>
    
  )
}

export default ForgotPassword
