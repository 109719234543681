import React, { useState, useEffect } from 'react';
import '../assets/scss/Footer.scss';
import { Link, NavLink } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faLinkedin, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
library.add(faFacebook, faTwitter, faLinkedin, faInstagram, faYoutube);


export default function Footer() {
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

// Get Current year 
  useEffect(() => {
    setCurrentYear(new Date().getFullYear());
  }, []);

  return (
    <div className='footer bottom-0 w-100'>
      <div className='container'>
        <div className='footer-menus d-flex justify-content-between flex-lg-row flex-md-column flex-column'>
          <ul className='footer-menu d-flex align-items-center gap-4 justify-content-lg-start justify-content-sm-center ps-0'>
            <li><NavLink href='#' className='text-decoration-none text-white' to="/about-us">About Us </NavLink></li>
            <li><NavLink href='#' className='text-decoration-none text-white' to="/contact-us"> Contact Us</NavLink></li>
            {/* <li><NavLink href='#' className='text-decoration-none text-white' to="/ultimate-guide"> Ultimate User Guide</NavLink></li> */}
            <li><NavLink href='#' className='text-decoration-none text-white' to="/privacy-policy">Privacy Policy </NavLink></li>
          </ul>
          <ul className='d-flex align-items-center justify-content-lg-end justify-content-sm-center justify-content-center gap-4'>
            <li className='footer-icone'><Link  className='btn text-white fs-5 '><FontAwesomeIcon icon={faFacebook} /></Link></li>
            <li className='footer-icone'><Link  className='btn text-white fs-5 '> <FontAwesomeIcon icon={faTwitter} /></Link></li>
            <li className='footer-icone'><Link  className='btn text-white fs-5 '><FontAwesomeIcon icon={faLinkedin} /></Link></li>
            <li className='footer-icone'><Link  className='btn text-white fs-5 '><FontAwesomeIcon icon={faInstagram} /></Link></li>
            <li className='footer-icone'><Link  className='btn text-white fs-5 '><FontAwesomeIcon icon={faYoutube} /></Link></li>
          </ul>
        </div>
        <div className='copyright text-center'>
        <p className='m-0'>© {currentYear} 405 live All Rights Reserved</p>
        </div>
      </div>
    </div>
  )
}