import React from 'react';
import '../App.scss';
import { Routes, Route, Navigate } from 'react-router-dom';
import Home from '../pages/Home';
import Event from '../pages/Events/EventPage/Event';
import EventDetails from '../pages/Events/EventPage/EventDetails';
import Venues from '../pages/Venues/Venues';
import VenueDetails from '../pages/Venues/VenueDetails'
import Search from '../pages/Search';
import SignIn from '../pages/AuthPages/SignIn/SignIn';
import SignUp from '../pages/AuthPages/SignUp';
import AboutUs from '../pages/AboutUs'
import ContactUs from '../pages/ContactUs'
import PrivacyPolicy from '../pages/PrivacyPolicy';
import ForgotPassword from '../pages/AuthPages/ForgotPassword';
import OtpVerify from '../pages/AuthPages/OtpVerify';
import Profile from '../pages/AuthPages/Profile'
import Favorite from '../pages/Favorite';
import ChangePassword from '../pages/AuthPages/ChangePassword';
import { AuthProvider } from '../pages/Header/AuthContext';
import PageNotFound from '../pages/AuthPages/PageNotFound';
import CreatorSingup from '../pages/AuthPages/CreatorSingup'
// Route Auth 

function PrivateProfileRoute({ element }) {
  const token = localStorage.getItem('token');
  if (token) {
    return element;
  } else {
    return <Navigate to="/sign-in" replace />;
  }
}

const routes = [
  {
    path: '/',
    element:<Home />
  },
  {
    path: '/events',
    element: <Event />
  },
  {
    path: '/event-details/:eventId',
    element: <EventDetails />
  },
  {
    path: '/venues',
    element: <Venues />
  },
  {
    path: '/venue-details/:venueId',
    element:<VenueDetails />
  },
  {
    path: '/favorite',
    element: <PrivateProfileRoute element={<Favorite />} />
  },
  {
    path: '/search',
    element: <Search />
  },
  {
    path: '/sign-in',
    element: <SignIn />
  },
  {
    path: '/sign-up',
    element: <SignUp />
  },
  {
    path: '/creator',
    element: <CreatorSingup/>
  },
  {
    path: '/profile',
    element: <PrivateProfileRoute element={<Profile />} />
  },
  {
    path: '/forgot-password',
    element: <ForgotPassword />
  },
  {
    path: '/otp-verify',
    element: <OtpVerify />
  },
  {
    path: '/change-password',
    element: <ChangePassword />
  },
  {
    path: '/about-us',
    element: <AboutUs />
  },
  {
    path: '/contact-us',
    element: <ContactUs />
  },
  {
    path: '/privacy-policy',
    element: <PrivacyPolicy />
  },
  {
    path: '*',
    element: <PageNotFound />
  }

]

function RouteManager() {
  return (
    <>
      <AuthProvider>
      <Routes>
        {routes.map((route) => (
          <Route
            key={route.path}
            path={route.path}
            element={route.element}
          ></Route>
        ))}
      </Routes>
    </AuthProvider>
    </>
  );
}

export default RouteManager;