import React from 'react';
import '../../assets/scss/PageNotFound.scss'
import { NavLink } from "react-router-dom";
import Header from '../Header/Header';
import Footer from '../Footer';
import { Helmet } from 'react-helmet';
import ServerName from '../../config';
function PageNotFound() {
  
  return (
    <>
    <Helmet>
      <title>PageNotFound</title>
      <meta content='page not-found'/>
      <meta name="keyword" content="pagenotfound, no events, no venues"/>
      <meta property='og:url' content={`${ServerName}`}/>
      <meta property='og:title' content="Page not-found  " />
      <meta property='og:description' content="Page not-found " />
      <meta property='og:image' content={`${ServerName}/logo192.png`}/>
      <link rel="canonical" href={`${ServerName}`} />
    </Helmet>
    <Header/>
    <html lang="en">
      <head>
        <meta charset="utf-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>404 HTML Tempate by Colorlib</title>
        <link href="https://fonts.googleapis.com/css?family=Maven+Pro:400,900" rel="stylesheet" />
        <link type="text/css" rel="stylesheet" href="css/style.css" />
        <script src="https://oss.maxcdn.com/html5shiv/3.7.3/html5shiv.min.js"></script>
        <script src="https://oss.maxcdn.com/respond/1.4.2/respond.min.js"></script>
      </head>
      <body>
        <div id="notfound">
          <div className="notfound">
            <div className="notfound-404">
              <h1>404</h1>
            </div>
            <h2>We are sorry, Page not found!</h2>
            <p>The page you are looking for might have been removed, had its name changed, or is temporarily unavailable.</p>
            <NavLink className="home-button" aria-current="page" to="/" >Back To HomePage</NavLink>
          </div>
        </div>
      </body>
    </html>
    <Footer/>
    </>
  );
}

export default PageNotFound;
