import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Header from '../../Header/Header';
import Footer from '../../Footer';
import '../../../assets/scss/Sign.scss';
import logo from '../../../assets/img/logo192.png';
import 'react-toastify/dist/ReactToastify.css';
import ServerName from '../../../config';
import GoogleSignIn from './GoogleSignIn';
import FacebookSignIn from './FacebookSignIn';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate, Link } from 'react-router-dom';
import { useAuth } from '../../Header/AuthContext';
import { Helmet } from 'react-helmet';
const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Regex for Email

const SignIn = () => {
  const { isLoggedIn, setIsLoggedIn } = useAuth();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState('');
  const [formSubmitted, setFormSubmitted] = useState(false);

useEffect(()=>{
  if(isLoggedIn){
    navigate('/')
  }
},[isLoggedIn])


  const handleSubmit = async (e) => {
    e.preventDefault();

    if (email === '' || password === '') {
      // Display error messages
      setFormSubmitted(true);
      return;
    }
    // Check email is valid or not 
    if (!emailRegex.test(email)) {
      setFormSubmitted(true);
      setEmailError('Enter a valid Email Address');
      return;
    }
    setEmailError(''); // clear input field after submit Form

    const items = {
      email: email,
      password: password,
    };

    /* ***********************  SignIn or Login  Api Call **********************/ 

    try {
      const response = await axios.post(ServerName+'/login', items);
      if (response.data.success) {
        const token = response.data.data.token;
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        localStorage.setItem('token', token);
        setIsLoggedIn(true);
        navigate('/');  // Navigate to Home Page
        setEmail('');
        setPassword('');
        setFormSubmitted(false); 
      }
      else {
        toast.error(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
      }

    } catch (error) {
      toast.error('An error occurred while logging in.', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
    }
  };


  return (
    <>
     <Helmet>
     <title>Sign-in Page</title>
      <meta name="title" content="Sign-in  page"/>
      <meta name="description" content='user can sign-in , login user '/>
      <meta name="keyword" content="login , sign-in"/>
      <meta property='og:url' content={`${ServerName}/sign-in`}/>
      <meta property='og:title' content="Sign-in  page" />
      <meta property='og:description' content="user can sign-in , login user, Admin login" />
      <meta property='og:image' content={`${ServerName}/logo192.png`}/>
      <link rel="canonical" href={`${ServerName}/sign-in`} />

    </Helmet>
      <Header />
      <div className='mx-auto '>
        <div className='sign d-flex w-100'>
          <div className=' text-center sign-left d-flex flex-column align-items-center justify-content-center'>
            <h3 className='login-title mt-5'> Hey There! Welcome 405 </h3>
            <div className='login-logo'>
              <img src={logo} alt='logo'/>
            </div>
            <span className='text-center mb-5'>Don't have an account? <Link href='#' className='text-decoration-none fw-bold' to="/sign-up">Sign Up</Link></span>
          </div>
          <div className='bg-white sign-right'>
            <div className='sign-form animated-form '>
              <div className="text-center">
                <h4 className="mt-5 mb-3 pb-1"> Sign In</h4>
              </div>
              <form onSubmit={handleSubmit}>
                <div className='sign-email'>
                  <label htmlFor='email'>Email: </label>
                  <input type='email' name='email' placeholder='example@gmail.com' value={email} onChange={(e) => setEmail(e.target.value)} />
                  {formSubmitted && email === '' && <span className='error-message'>Enter a valid Email Address</span>}
                  {formSubmitted && email !== '' && emailError && <span className='error-message'>{emailError}</span>}
                </div>
                <div className='sign-pass'>
                  <label htmlFor='passw'>Password: </label>
                  <input type='password' name='passw' value={password}
                    onChange={(e) => setPassword(e.target.value)} />
                  {formSubmitted && password === '' && <span className='error-message'>Enter Password</span>}
                  <div className='d-flex justify-content-between remember-forgot'>
                    <span className='mt-2'>
                      <a className='remember-pass text-decoration-none d-flex' > <input type="checkbox" className='chekbox me-2' /> Remember Me</a>
                    </span>
                    <span className='mt-2'><Link className='text-decoration-none' to='/forgot-password'>Forgot Password?</Link></span>
                  </div>
                </div>
                <button className='sign-btn' type='submit'>Login <ToastContainer /></button>
                <div className='singn-up-width text-center mt-2'>
                  <h6 className='text-center mt-3'>
                    <span className=''>Or Login with</span></h6>
                  <div className='login-with-btn my-4 d-flex gap-2  justify-content-center'>
                    <FacebookSignIn />
                    <GoogleSignIn />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default SignIn;