import React, { useState,useEffect } from 'react'
import axios from 'axios';
import '../../assets/scss/Venues.scss'
// import styled from 'styled-components';
import Header from '../Header/Header'
import Footer from '../Footer';
import Loading from '../Loading';
// import Animation from '../../assets/img/titledesigen.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { SmallCardAfter, containerStyle } from '../ContainerStyle';
import ServerName from '../../config';
import { Helmet } from 'react-helmet';

function Venues() {
  
  const[venues, setVenues]= useState('')
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const navigate = useNavigate();

/* **********************FetchAllVenue ***********************/

  const FetchAllVenue = async () => {
    try {
      const response = await axios.get(ServerName+'/get_venues_list');
      return response.data.data;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  };

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const [venues] = await Promise.all([ FetchAllVenue()]);
        setVenues(venues);
        setLoading(false);
      } catch (error) {
        setError('Error fetching data. Please try again later.');
        setLoading(false);
      }
    };
    fetchData();
  }, []);

// navigate to venue Details page
  const handleVenueClick = (venue) => {
    navigate(`/venue-details/${venue.id}`);
  };

// Truncate Address
  const truncateAddress = (address, maxLength) => {
    if (!address) {
      return ''; // Return an empty string if address is undefined
    }
    if (address.length <= maxLength) {
      return address;
    }
    return `${address.slice(0, maxLength)} ...`;
  };



  return (
    <>
     <Helmet>
      <title>Venue Page</title>
      <meta name="title" content="Venue page"/>
      <meta name="description" content='Get all info of venues, explore venues'/>
      <meta name="keyword" content="events venues,eventsDetails"/>
      <meta property='og:url' content={`${ServerName}/logo192.png`}/>
      <meta property='og:title' content="Vennue Page" />
      <meta property='og:description' content="Get all info of venues, explore venues" />
      <meta property='og:image' content={`${ServerName}/logo192.png`}/>
      <link rel="canonical" href={`${ServerName}/venues`} />
    </Helmet>
    <Header />
    <div className='venues'>
      <div className='container'>
        <div className='venues-slider-navbar  row'>
        <SmallCardAfter className='section-title position-relative col-xxl-2 col-xl-12'>
              <h2 className='ps-3'>Venues</h2>
          </SmallCardAfter>
        </div>
        <div className='venues-card'>
          {loading ? <div style={containerStyle}><Loading /></div> : (
            <div className='venues-post row'>
              {Array.isArray(venues) && venues.length > 0 ? (
                venues.map((venue, index) => (
                  <div className=' col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 p-3 ' key={index}>
                    <div className='text-bg-light venues-post-details h-100' onClick={() => handleVenueClick(venue)} >
                      <div className='venues-img'>
                        <img src={venue.image} className='' alt='venue_image'/>
                      </div>
                      <div className='venues-information'>
                        <a className='location text-decoration-none d-flex' >
                          <FontAwesomeIcon icon={faMapMarkerAlt} className='post-location-icon pe-2' />
                         <span>{truncateAddress(venue.address, 40)}, {venue.city}, {venue.state}, {venue.zip_code}</span> 
                         {/* <span>{venue.address}</span> */}
                        </a>
                        <p className='post-description pe-2 mb-0'>
                          <a className='text-decoration-none' >{venue.name}</a>
                        </p>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className='col-12 text-center '>No Events Found</div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
    <Footer />
  </>
  )
}

export default Venues
