import React, { useState } from 'react';
import all_events from '../../assets/img/all_event.svg';
import Slider from 'react-slick';
import Loading from '../Loading'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { settings, SmallCardAfter, containerStyle } from '../ContainerStyle';
// import DatePicker from 'react-datepicker';
import Select from 'react-select';





// import { DemoItem } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Box from '@mui/material/Box';
// import Alert from '@mui/material/Alert';


function EventComponent({
  onDateChange,
  selectedFilter,
  toggleFilter,
  showAllEvents,
  selectedCategories,
  handleFilterChange,
  loading,
  events,
  handleEventClick,
  categories,
  selectedDate,
  handleLocationChange,
  options,
  locationId,
}) {

  const [cleared, setCleared] = useState(false);
  /***************Render Categories **************/
  const renderCategories = (categories, selectedCategories, handleFilterChange, selectedFilter) => {
    if (!Array.isArray(categories) || categories.length === 0) {
      return <p></p>;
    }
    return categories.map((category) => (
      <a
        key={category.id}
        className={`catigare py-4 d-flex flex-column text-center align-items-center border rounded text-decoration-none ${selectedCategories.includes(category.id) ? 'active' : ''}`}
        onClick={() => handleFilterChange(selectedFilter, category.id)}
      >
        <img src={category.image_path} className='event_img' alt="Image" />
        <p className='catigare-name pt-2 m-0'>{category.name}</p>
      </a>
    ));
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', { day: '2-digit' });
  };


  const formatMonth = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', { month: 'short' });
  };



  return (
    <>

      <div className='event'>
        <div className='container'>
          <div className='event-slider-navbar  row'>
            <SmallCardAfter className='section-title position-relative col-xxl-2 col-xl-12'>
              <h2 className='ps-3'>Events</h2>
            </SmallCardAfter>
            <div className='slider-menu d-flex justify-content-end align-items-center col-xxl-10 col-xl-12 mt-xxl-0 mt-md-4'>


              <div className="select-date d-flex align-items-center pe-3">
                <label className='pe-2'>Location:
                </label>
                
                  {/* <Select
                    value={options.find((option) => option.value === locationId)}
                    onChange={handleLocationChange}
                    options={options}
                    placeholder="Select Location"
                    isSearchable
                  /> */}
  
                 <select 
                    className="form-select"
                    onChange={handleLocationChange} 
                    placeholder="Select Location"
                  >
                    {options.map(option => (
                      <option key={option.value} 
                      value={option.value}
                      >
                        {option.label}
                      </option>
                    ))}
                  </select>

              </div>

              <div className='select-date d-flex align-items-center'>
                <label className='pe-3'>Select Date:</label>
                <div>
                  {/* <input type='date' id="dateInput" onChange={onDateChange} /> */}
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Box
                      sx={{
                        width: '90%',
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        position: 'relative',
                        backgroundColor: '#ECF3F9',
                        borderRadius: "5px"
                      }}
                    >
                      <DatePicker
                        sx={{ width: 260 }}
                        slotProps={{
                          field: { clearable: true, onClear: () => setCleared(true) },
                        }}
                        value={selectedDate}
                        onChange={onDateChange}
                        disablePast
                      />
                    </Box>
                  </LocalizationProvider>
                </div>
              </div>
              <div className="form-floating mb-4 d-flex">
              </div>
              <div className='filter d-flex align-items-center'>
                <label className='px-3'>Filter By:</label>
                <ul className='filter-tabs d-flex align-items-center m-0 p-2'>
                  <li
                    className={`px-4 py-2 mx-1 ${selectedFilter === 'today' ? 'active' : ''}`}
                    onClick={() => toggleFilter('today')}
                  >
                    Today
                  </li>
                  <li
                    className={`px-4 py-2 mx-1 ${selectedFilter === 'week' ? 'active' : ''}`}
                    onClick={() => toggleFilter('week')}
                  >
                    This Week
                  </li>
                  <li
                    className={`px-4 py-2 mx-1 ${selectedFilter === 'weekend' ? 'active' : ''}`}
                    onClick={() => toggleFilter('weekend')}
                  >
                    This Weekend
                  </li>
                  <li
                    className={`px-4 py-2 mx-1 ${selectedFilter === 'month' ? 'active' : ''}`}
                    onClick={() => toggleFilter('month')}
                  >
                    This Month
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className='cards'>
            <div className=''>
              <Slider {...settings} className='smallcard'>
                {showAllEvents && (
                  <a
                    className={`catigare px-2 py-4 mx-sm-auto h-100  d-flex flex-column text-center align-items-center border rounded text-decoration-none ${selectedCategories.includes('all') ? 'active' : ''}`}
                    onClick={() => handleFilterChange(selectedFilter, 'all')}
                  >
                    <img src={all_events} className='event-img' alt='All Events' />
                    <p className='catigare-name pt-2 m-0'>All Events</p>
                  </a>
                )}
                {renderCategories(categories, selectedCategories, handleFilterChange, selectedFilter)}
              </Slider>
            </div>
          </div>
          <div className='events'>
            {loading ? <div style={containerStyle}><Loading /></div> : (
              <div className='event-post row'>
                {Array.isArray(events) && events.length > 0 ? (
                  events.map((event) => (
                    <div className=' col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 p-3 ' key={event.id}>
                      <div className='text-bg-light event-post-details h-100' onClick={() => handleEventClick(event.id)} >
                        <div className='event-img position-relative'>
                          <img src={event.image} alt='event_image' className='w-100' />
                          <div className='pop-date position-absolute text-white'>
                            <h4 className='m-0'>{formatDate(event.event_date)}</h4>
                            <p className='m-0'>{formatMonth(event.event_date)}</p>
                          </div>
                          <div className='pop-days position-absolute text-white d-flex align-items-center text-center'>
                            <span className=''>
                              {Math.ceil((new Date(event.event_date.split(" ")[0]) - new Date()) / (1000 * 60 * 60 * 24)) <= 0
                                ? 'Today'
                                : Math.ceil((new Date(event.event_date.split(" ")[0]) - new Date()) / (1000 * 60 * 60 * 24)) === 1
                                  ? '1 day left'
                                  : `${Math.ceil((new Date(event.event_date.split(" ")[0]) - new Date()) / (1000 * 60 * 60 * 24))} days left`}
                            </span>
                          </div>
                        </div>
                        <div className='event-information'>
                          <a className='location text-decoration-none d-flex'>
                            <FontAwesomeIcon icon={faMapMarkerAlt} className='post-location-icon pe-2' /> {event.location}
                          </a>
                          <p className='post-description pe-2 mb-0'>
                            <a className='text-decoration-none'>{event.title}</a>
                          </p>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className='col-12 text-center '> No Events Found</div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default EventComponent;
