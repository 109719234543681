import React, { useState, useEffect } from 'react'
import Header from '../Header/Header';
import Footer from '../Footer';
import logo from '../../assets/img/logo192.png';
// import facebook from '../../assets/img/login-facebook.svg';
// import google from '../../assets/img/login-google.svg';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate, Link } from 'react-router-dom';
import GoogleSignIn from './SignIn/GoogleSignIn';
import FacebookSignIn from './SignIn/FacebookSignIn';
import '../../assets/scss/SignUp.scss'
import ServerName from '../../config';
import { useAuth } from '../Header/AuthContext';
import { Helmet } from 'react-helmet';
const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Regex for Email


function SignUp() {
    const { isLoggedIn, setIsLoggedIn } = useAuth();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [Birthdate, setBirthdate] = useState('');
    const [emailError, setEmailError] = useState('');
    const [formSubmitted, setFormSubmitted] = useState(false);

    const navigate = useNavigate();

    useEffect(()=>{
        if(isLoggedIn){
          navigate('/')
        }
      },[isLoggedIn])

    /* ***********************  SignUp  Api Call **********************/ 
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (name === '' || email === '' || password === '' || Birthdate === '') {
            // Display error messages
            setFormSubmitted(true);
            return;
        }
        if (!emailRegex.test(email)) {
            setFormSubmitted(true);
            setEmailError('Enter a valid Email Address');
            return;
        }
        setEmailError('');
        // send Data (items) to backend After Registration by user 
        const items = {
            name: name,
            email: email,
            birthdate: Birthdate,
            password: password,
            user_type: 'normal'
        };

        try {
            const response = await axios.post(ServerName+'/register', items);
            if (response.data.success) {
                const token = response.data.token;
                axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
                toast.success('Register successfully.', {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
                await new Promise((resolve) => setTimeout(resolve, 2000));
                navigate('/sign-in');
            }
            else {
                toast.error(response.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
            }
            setEmail(''); // clear Input field values When user Submit Form 
            setPassword('');
            setBirthdate('');
            setName('');
            setFormSubmitted(false);

        } catch (error) {
            toast.error('An error occurred while registering.', {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
            });
        }
    };

    return (
        <>
        <Helmet>
        <title>Sign-up Page</title>
        <meta name="title" content="Sign-up  page"/>
        <meta name="description" content='user can sign-up , create user '/>
        <meta name="keyword" content="create account , sign-up"/>
        <meta property='og:url' content={`${ServerName}/sign-up`}/>
        <meta property='og:title' content="Sign-in  page" />
        <meta property='og:description' content="user can sign-up , create account user, Admin sign-up" />
        <meta property='og:image' content={`${ServerName}/logo192.png`}/>
        <link rel="canonical" href={`${ServerName}/sign-up`} />
        </Helmet>
            <Header />
            <div className='mx-auto '>
                <div className='sign d-flex w-100'>
                    <div className=' text-center sign-left d-flex flex-column align-items-center justify-content-center'>
                        <h3 className='login-title mt-5'> Hey There! Welcome 405 </h3>
                        <div className='login-logo'>
                            <img src={logo} alt='logo' />
                        </div>
                        <span className='text-center mb-5'> Already a member? <Link href='#' className='text-decoration-none fw-bold' to="/sign-in">Sign in</Link></span>
                    </div>
                    <div className='bg-white sign-right'>
                        <div className='sign-form animated-form '>
                            <div className="text-center">
                                <h4 className="mt-5 mb-3 pb-1"> Sign Up</h4>
                            </div>
                            <form onSubmit={handleSubmit}>
                                <div className='sign-email'>
                                    <label htmlFor='text'>Enter your name: </label>
                                    <input type='text' name='name' placeholder='example@gmail.com' value={name} onChange={(e) => setName(e.target.value)} />
                                    {formSubmitted && name === '' && <span className='error-message'>Enter Name</span>}
                                </div>
                                <div className='sign-email'>
                                    <label htmlFor='text'>Enter your Birthdate: </label>
                                    <input type='date' 
                                    name='birthdate' 
                                    placeholder='example@gmail.com' 
                                    value={Birthdate} 
                                    onChange={(e) => setBirthdate(e.target.value)}
                                    max={new Date().toISOString().split('T')[0]}
                                    />
                                    {formSubmitted && Birthdate === '' && <span className='error-message'>Enter Birthdate</span>}
                                
                                </div>
                                <div className='sign-email'>
                                    <label htmlFor='email'>Email: </label>
                                    <input type='email' name='email' placeholder='example@gmail.com' value={email} onChange={(e) => setEmail(e.target.value)} />
                                    {formSubmitted && email === '' && <span className='error-message'>Enter a valid Email Address</span>}
                                    {formSubmitted && email!== '' && emailError && <span className='error-message'>{emailError}</span>}
                                </div>
                                <div className='sign-pass'>
                                    <label htmlFor='passw'>Password: </label>
                                    <input type='password' name='passw' value={password} onChange={(e) => setPassword(e.target.value)} />
                                    {formSubmitted && password === '' && <span className='error-message'>Enter Password</span>}
                               
                                </div>
                                <button className='sign-btn' type='submit'>Sign Up <ToastContainer /></button>
                                <div className='singn-up-width text-center mt-2'>
                                    <h6 className='text-center mt-3'>
                                        <span href='#' className=' '>Or Sign Up with</span></h6>
                                    <div className='login-with-btn my-4 d-flex gap-2  justify-content-center'>
                                        {/* <a href='#' className='text-decoration-none py-2 px-4 border rounded-1'><img src={facebook} /> </a>
                                        <a href='#' className='text-decoration-none py-2 px-4 border rounded-1'><img src={google} /> </a> */}
                                        <FacebookSignIn />
                                        <GoogleSignIn />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default SignUp
