import React, { useEffect } from 'react';
import Header from './Header/Header'
import Footer from './Footer'
// import '../AboutUs/AboutUs.scss'
import '../assets/scss/AboutUs.scss'

import aboutimg from '../assets/img/405-bennar.png'
// import music from '../../assets/img/team.png'
// import people from '../../assets/img/logo192.png'
import sponcer2 from '../assets/img/sponcer_img2.png'
import sponcer1 from '../assets/img/sponcer_img1.png'
import sponcer3 from '../assets/img/sponcer_img3.png'
import sponcer4 from '../assets/img/sponcer_img4.png'
import sponcer5 from '../assets/img/sponcer_img5.png'
import sponcer6 from '../assets/img/sponcer_img1.png'
import sponcer7 from '../assets/img/sponcer_img2.png'
import sponcer8 from '../assets/img/sponcer_img3.png'
import { Helmet } from 'react-helmet';
import ServerName from '../config';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
function AboutUs() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  return (
    <>
    <Helmet>
      <title>About-us Page</title>
      <meta name="title" content="About-us page"/>
      <meta name="description" content='about us information'/>
      <meta name="keyword" content="About-us , get info"/>
      <meta property='og:url' content={`${ServerName}/about-us`}/>
      <meta property='og:title' content="About-us page" />
      <meta property='og:description' content="Get all info" />
      <meta property='og:image' content={`${ServerName}/logo192.png`}/>
      <link rel="canonical" href={`${ServerName}/about-us`} />
      </Helmet>
      <Header />
      <section className='aboutas-section'>
        <div className='container'>
          <div className='about-as mt-5'>
            <div className='sectiion-title'>
              <h1 className=''> About Us </h1>
            </div>
            <div className='about-img text-cemter my-5'>
              <img src={aboutimg} className='w-100 h-50' />
            </div>
            <div className='aboutas-details'>
              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi corrupti aliquam porro velit quaerat veritatis doloribus exercitationem quo pariatur perspiciatis. Vel modi provident illum animi amet rerum similique! Fuga, corporis. Vel modi provident illum animi amet rerum similique! Fuga, corporis.
              </p>
              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi corrupti aliquam porro velit quaerat veritatis doloribus exercitationem quo pariatur perspiciatis. Vel modi provident illum animi amet rerum similique! Fuga, corporis.</p>
              <p>Interested in learning more about our historic building? Book a docent guided tour.</p>
            </div>
          </div>
        </div>
      </section>
    
      {/* ***********************Sponsor-section**********************/}
      <section className='sponsor-section text-center py-3 my-5'>
        <div className='container'>
          <div className='sponsor-title pb-5'>
            <h2>Sponsor Of Big <br />Company</h2>
          </div>
          <div className='row'>
            <div className='col-md-3 col-sm-6 col-12 mb-5 '>
              <div className='sponcer-img'>
                <img src={sponcer1} alt='sponcer1' />
              </div>
            </div>

            <div className='col-md-3 col-sm-6 col-12 mb-5 '>
              <div className='sponcer-img'>
                <img src={sponcer2} alt='sponcer2' />
              </div>
            </div>

            <div className='col-md-3 col-sm-6 col-12 mb-5 '>
              <div className='sponcer-img'>
                <img src={sponcer3} alt='sponcer3' />
              </div>
            </div>

            <div className='col-md-3 col-sm-6 col-12 mb-5 '>
              <div className='sponcer-img'>
                <img src={sponcer4} alt='sponcer4' />
              </div>
            </div>

            <div className='col-md-3 col-sm-6 col-12 mb-5 '>
              <div className='sponcer-img'>
                <img src={sponcer5} alt='sponcer5'/>
              </div>
            </div>

            <div className='col-md-3 col-sm-6 col-12 mb-5 '>
              <div className='sponcer-img'>
                <img src={sponcer6} alt='sponcer6'/>
              </div>
            </div>

            <div className='col-md-3 col-sm-6 col-12 mb-5 '>
              <div className='sponcer-img'>
                <img src={sponcer7} alt='sponcer7' />
              </div>
            </div>

            <div className='col-md-3 col-sm-6 col-12 mb-5 '>
              <div className='sponcer-img'>
                <img src={sponcer8} alt='sponcer8' />
              </div>
            </div>

          </div>
        </div>
      </section>
      <Footer />
    </>
  )
}

export default AboutUs
