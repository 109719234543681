import React, { useState, useEffect } from 'react';
import '../../assets/scss//Header.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faHeart } from '@fortawesome/free-regular-svg-icons';
import { faCalendarAlt, faArrowRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import navbarlogo from '../../assets/img/navbarlogo.png';
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import { Dropdown } from 'react-bootstrap';
import { useAuth } from './AuthContext';
import ServerName from '../../config';
const Header = () => {

  const { isLoggedIn, setIsLoggedIn } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  // Get Token From Local Storage 
  useEffect(() => {
    const token = localStorage.getItem('token');
    setIsLoggedIn(!!token);
  }, [isLoggedIn]);


  // it should show menu and after clicking menu then show X icon
  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };


  // when user click on search 
  const handleSearch = () => {
    if (searchTerm.trim() !== '') {
      navigate(`/search?query=${encodeURIComponent(searchTerm)}`);
    }
  };

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  // when user click on search Button 
  const handleSearchButton = () => {
    navigate(`/search?query=${encodeURIComponent(searchTerm)}`);

  };

  // when user PressEnter on search Area 
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      navigate(`/search?query=${encodeURIComponent(searchTerm)}`);
    }
  };

  React.useEffect(() => {
    const params = new URLSearchParams(location.search);
    const query = params.get("query");
    setSearchTerm(query || "");
  }, [location.search]);

  // Get Date, Monthand Year 
  const currentDate = new Date();
  const day = currentDate.getDate();
  const month = currentDate.toLocaleString('default', { month: 'long' });
  const year = currentDate.getFullYear();

  const handleLogout = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        // Token doesn't exist, no need to log out
        return;
      }

      const response = await fetch(ServerName + '/web_logout', {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 200 || response.status === 401) {
        // If the logout was successful on the server, proceed to log out on the client-side
        localStorage.removeItem('token');
        setIsLoggedIn(false);
        navigate('/');
      } else {
        console.log('Failed to log out on the server');
      }
    } catch (error) {
      console.error('An error occurred while logging out:', error);
    }
  };

  return (
    <header>
      <nav>
        <div className='topbar d-flex'>
          <div className='date  d-flex align-items-cen  ter'>
            <p className='mb-0'><FontAwesomeIcon icon={faCalendarAlt} /> {day} {month} {year}</p>
          </div>
          <div className='search'>
            <div className='search-input'  >
              <input type="text"
                value={searchTerm}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
                placeholder="Search..." />
              <a className='text-white' onClick={handleSearch}>
                <FontAwesomeIcon
                  icon={faSearch}
                  className='p-0'
                  onClick={handleSearchButton}
                />
              </a>
            </div>

          </div>
        </div>
        <nav className="navbar navbar-expand-md">
          <div className="container-fluid p-0">
            <NavLink className="navbar-brand" to="/"> <img src={navbarlogo} alt='navbar logo' aria-label='Go to Home Page' /></NavLink>
            <button
              className="navbar-toggler"
              type="button"
              onClick={toggleNav}
              aria-expanded={isNavOpen}
              aria-label="Toggle navigation"
            >
              <FontAwesomeIcon icon={isNavOpen ? faTimes : faBars} style={{ color: 'White', fontSize: '24px', border: '0px' }} />
            </button>

            <div className={`collapse navbar-collapse ${isNavOpen ? 'show' : ''}`} id="navbarTogglerDemo01">
              <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                <li className="nav-item text-align-center text-center">
                  <NavLink className="nav-link" aria-current="page" to="/" >Home</NavLink>
                </li>
                <li className="nav-item text-center">
                  <NavLink className="nav-link" to="/events">Events</NavLink>
                </li>
                <li className="nav-item text-center">
                  <NavLink className="nav-link" to="/venues">Venues</NavLink>
                </li>
                {/* <li>
                <NavLink className='nav-btn' to="/creator">Creator</NavLink>
                </li> */}
              </ul>
              {!isLoggedIn ? (
                // <NavLink className='nav-btn' to="/sign-in">Sign In</NavLink> 
                <div className='d-md-flex justify-content-center login-creater'>
                  <div className='d-flex justify-content-center '>
                    <NavLink className='nav-btn' to="/sign-in">Sign In</NavLink>
                  </div>
                  <div className='d-flex justify-content-center '>
                    <NavLink className='nav-btn2' to="/creator">Event Creator</NavLink>
                  </div>
                </div>
              ) : (
                <Dropdown className='d-flex justify-content-center'>
                  <Dropdown.Toggle variant="secondary" id="dropdown-basic" className='d-flex align-items-center profile-icon'>
                    <div className="circular-icon me-2">
                      <FontAwesomeIcon icon={faUser} style={{ color: "#ffffff", }} />
                    </div>
                    <span>
                      Settings
                    </span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className='p-0'>
                    <Dropdown.Item className='dropdown-item d-flex align-items-center gap-2 p-2 ' as={NavLink} to="/profile"> <FontAwesomeIcon icon={faUser} style={{ color: "#000000", }} className='dropdown-icon' /> Profile</Dropdown.Item>
                    <Dropdown.Item className='dropdown-item d-flex align-items-center gap-2 p-2 my-1' as={NavLink} to="/favorite"><FontAwesomeIcon icon={faHeart} style={{ color: "#000000", }} className='dropdown-icon' /> Favorites</Dropdown.Item>
                    <Dropdown.Item className='dropdown-item d-flex align-items-center gap-2 p-2' onClick={handleLogout}><FontAwesomeIcon icon={faArrowRightFromBracket} style={{ color: "#000000", }} className='dropdown-icon' /> Logout</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              )}
            </div>
          </div>
        </nav>
      </nav>
    </header>
  )
}

export default Header;
