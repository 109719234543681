 import styled from 'styled-components';
 import Animation from '../assets/img/titledesigen.svg';


//Style of Loading 

export const containerStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',

};

export const settings = {
    dots: false,
    infinite: false,
    speed: 1000,
    slidesToShow: 9,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 7,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
};


export const SmallCardAfter = styled.section`
  &::after {
    content: url(${Animation});
    position: absolute;
    top: -19px;
    left: 0px;
  }
`;

export const EventBtn = styled.section`
  &::after {
    content: " ";
    position: absolute;
    top: 25px;
    right: 0;
    border-bottom: 1px solid #A7B9C9;
    width: 44.5%;
  }
  &::before {
    content: " ";
    position: absolute;
    top: 25px;
    left: 0;
    border-bottom: 1px solid #A7B9C9;
    width: 44.5%;
  }
`;
