import React, { useState, useEffect } from 'react'
import Header from '../Header/Header'
import Footer from '../Footer'
import logo from '../../assets/img/logo192.png';
import axios from 'axios';
import ServerName from '../../config'
import { useNavigate, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import { ToastContainer, toast } from 'react-toastify';
import { Helmet } from 'react-helmet';

function OtpVerify() {
  const [OTPinput, setOTPinput] = useState('');
  const [error, setError] = useState(null);
  const [timer, setTimer] = useState(60);
  const navigate = useNavigate();
  const location = useLocation();
  const userEmail = location.state && location.state.email;


  /************************Verify Otp Api Call**********************/

  const verifyOTP = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(ServerName + '/verify_otp', {
        email: userEmail,
        otp: parseInt(OTPinput),
      });
      console.log("otp verify response", response.data);
      if (response.data.success) {
        navigate('/change-password', { state: { email: userEmail, otp: OTPinput } }); //sent email and otp to change password page
      } else {
        toast.error(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
      }
    } catch (error) {
      setError("An error occurred while verifying OTP. Please try again later.");
    }
  };


  /************************1 min Timer for resend otp option**********************/

  useEffect(() => {
    let interval;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }
    return () => {
      clearInterval(interval);
    };
  }, [timer]);

  /************************Time Format**********************/

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  };


  /* ***********************Resend Otp Api Call**********************/
  const resendOTP = async (e) => {
    e.preventDefault();
    const items = {
      email: userEmail,
    };
    try {
      const response = await axios.post(ServerName + '/forgot_password', items);
      if (response.data.success) {
        console.log("Sendind Otp for Otp Verification", response.data);
      }
    } catch (error) {
      setError("An error occurred while Resend OTP. Please try again later.");
    }
    setTimer(60);
  };

  return (
    <>
      <Helmet>
        <title>OTP-Verify Page</title>
        <meta name="title" content="otp-verify  page" />
        <meta name="description" content='user can verify-otp , login user ' />
        <meta name="keyword" content="login , verify-otp" />
        <meta property='og:url' content={`${ServerName}/otp-verify`} />
        <meta property='og:title' content="verify-otp  page" />
        <meta property='og:description' content="user can verify-otp , login user, Admin login" />
        <meta property='og:image' content={`${ServerName}/logo192.png`} />
        <link rel="canonical" href={`${ServerName}/otp-verify`} />
      </Helmet>
      <Header />
      <div className='mx-auto '>
        <div className='sign d-flex w-100'>
          <div className=' text-center sign-left forgot-pass d-flex flex-column align-items-center justify-content-center'>
            <h3 className='login-title mt-5'> Hey There! Welcome 405 </h3>
            <div className='login-logo'>
              <img src={logo} alt='logo' />
            </div>
          </div>
          <div className='bg-white sign-right forgot-pass'>
            <div className='sign-form animated-form '>
              <div className="text-center">
                <h4 className="mt-5 mb-3 pb-1">Otp Verification</h4>
              </div>
              <form>
                <div className='sign-email'>
                  <label htmlFor='email'>Enter your otp: </label>
                  <input
                    type='text'
                    name='otp'
                    placeholder='Enter 6 digit your otp'
                    pattern='[0-9]*'
                    maxLength='6'
                    value={OTPinput}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      if (/^[0-9]*$/.test(inputValue) && inputValue.length <= 6) {
                        setOTPinput(inputValue);
                      }
                    }}
                  />
                </div>
                <button
                  className='sign-btn'
                  type='submit'
                  onClick={verifyOTP}>
                  Verify <ToastContainer />
                </button>
                <div className='resend py-3'>
                  {timer > 0 ? (
                    <div className='text-center py-2 bg-light rounded-pill mx-auto'
                      style={{
                        border: 'none',
                        outline: 'none',
                        color: 'blue',
                      }}
                    >
                      <FontAwesomeIcon icon={faClock} style={{ color: '#2c84e2', marginRight: '8px' }} />
                      {formatTime(timer)}
                    </div>
                  ) : (
                    <div className='text-center py-2'>
                      Didn't receive code? <button
                        style={{
                          background: 'none',
                          border: 'none',
                          outline: 'none',
                          cursor: 'pointer',
                          color: 'blue',
                        }}
                        onClick={resendOTP} >Resend </button>
                    </div>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default OtpVerify
