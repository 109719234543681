import React, { createContext, useContext, useState } from 'react';
// import axios from 'axios';
// import ServerName from '../../config';

const SmallCardContext = createContext();

export function SmallCardProvider({ children }) {
  const [events, setEvents] = useState([]);
  const [categories, setCategories] = useState(['all']);
  const [showAllEvents, setShowAllEvents] = useState(false);


  const contextValue = { events, setEvents, categories, setCategories, showAllEvents};

  return (
    <SmallCardContext.Provider value={contextValue}>
      {children}
    </SmallCardContext.Provider>
  );
}

export function useSmallCard() {
  return useContext(SmallCardContext);
}

