// const ServerName = 'http://54.162.38.112:8080/api/web';
// const ServerName = 'http://405-live-406701576.us-east-1.elb.amazonaws.com'


const ServerName =`${window.location.protocol}//${window.location.hostname}/api/web`;

export default ServerName;

// const getBaseUrl = () => {

//     const basePort = process.env.REACT_APP_BASE_PORT || 8080;
//     const baseSuffix = process.env.REACT_APP_BASE_PREFIX || '/api/web';

//     console.log(process.env.NODE_ENV)

//     if (process.env.NODE_ENV === 'production') {
//       // Use production URL
//       console.log("env",process.env.NODE_ENV)
//       console.log("server name ",process.env.REACT_APP_BASE_URL_PROD)

//       return `${process.env.REACT_APP_BASE_URL_PROD}`;

//     } else if (process.env.NODE_ENV === 'testing') {
//       // Use testing URL
//       return `${process.env.REACT_APP_BASE_URL_TESTING}:${basePort}${baseSuffix}`;

//     } else if (process.env.NODE_ENV === 'development') {
//       // Default to development URL
//       return `${process.env.REACT_APP_BASE_URL_DEV}:${basePort}${baseSuffix}`;
//     }
//   };
//   const ServerName = getBaseUrl();
//   console.log("env",process.env.NODE_ENV)
//   console.log(ServerName);
//   export default ServerName;
  