import React from "react";
import { LoginSocialGoogle } from "reactjs-social-login";
import google from '../../../assets/img/login-google.svg';
import { useNavigate } from "react-router-dom";
import ServerName from "../../../config";

/* *************** Google SignIn and SignUp social_login Api Call ****************/

function GoogleSignIn() {
  const navigate = useNavigate()
  const sendUserDataToBackend = async (userData, token) => {
    try {
      const response = await fetch(ServerName + '/api/web/social_login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(userData),
      });
      const responseData = await response.json();
      localStorage.setItem('token', responseData.data.token);
      navigate('/');
    } catch (error) {
      console.error('Google login error:', error);
    }
  };
  return (
    <div>
      <LoginSocialGoogle
        client_id={"539050299211-hhbs877c3v7oabu9qvspdlpgsalm78gq.apps.googleusercontent.com"}
        scope="openid profile email"
        discoveryDocs="claims_supported"
        access_type="offline"
        onResolve={({ provider, data }) => {
          const userData = {
            email: data.email,
            name: data.name,
            birthday: data.birthday,
            user_type: 'google'
          };
          sendUserDataToBackend(userData);
        }}
        onReject={(err) => {
          console.log(err);
        }}
      >
        <a className='text-decoration-none py-3 px-4 border rounded-1'><img src={google} /></a>
      </LoginSocialGoogle>

    </div>
  );
}

export default GoogleSignIn;





