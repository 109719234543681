import React, { useState, useEffect } from 'react'
import axios from 'axios';
import '../assets/scss/Feature.scss'
import styled from 'styled-components';
import AnimationTwo from '../assets/img/titledesigen.svg';
import { useNavigate } from 'react-router-dom';
import Loading from './Loading';
import clock from '../assets/img/clock.svg'
import { containerStyle } from './ContainerStyle';
import ServerName from '../config';

const Titledesigen = styled.section`
  &::after {                  
    content: url(${AnimationTwo});
    position: absolute;
    top: -19px;
    left: 0;
  }
`;


export default function FeatureSidebar() {
  const navigate = useNavigate();
  const [venuesData, setVenuesData] = useState([]);
  const [selectedVenueData, setSelectedVenueData] = useState(null);
  const [activeVenueId, setActiveVenueId] = useState(null);
  const [loading, setLoading] = useState(false);

  /* *****************  fetchAllFeatures  Api Call ***************/

  const fetchAllFeatures = async () => {
    try {
      const response = await axios.get(ServerName + '/get_all_featured_venues_list');
      return response.data.data;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  };

  /* *****************  fetchByVenueId  Api Call ***************/

  const fetchByVenueId = async (venueId) => {
    try {
      const response = await axios.get(ServerName + '/get_events_by_venue', {
        params: {
          venue_id: venueId,
        },
      });
      return response.data.data;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  };

  useEffect(() => {
    // Fetch data from the API and update the state
    setLoading(true);
    fetchAllFeatures().then((data) => {
      setVenuesData(data);
      setLoading(false);
      // get events based on firstvenue Id 
      if (data.length > 0) {
        setLoading(false);
        setActiveVenueId(data[0].id);
        fetchByVenueId(data[0].id).then((selectedData) => setSelectedVenueData(selectedData));

      }
    });
  }, []);



  const handleVenueClick = async (venueId) => {
    // Fetch event data for the clicked venue and update the state
    try {
      const data = await fetchByVenueId(venueId);
      setSelectedVenueData(data);
      setActiveVenueId(venueId);
    } catch (error) {
      console.log(error);
    }
  };


  /********* formatDate ************/

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', { day: '2-digit' });
  };

  /* ********** formatMonth ***********/

  const formatMonth = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', { month: 'short' });
  };

  /*********** formatTimeRange ***********/

  const formatTimeRange = (startTime, endTime) => {
    if (!startTime || !endTime) return '';
    const formatTime = (timeString) => {
      const [time, amPM] = timeString.split(' ');
      const [hours, minutes] = time.split(':').map(Number);
      const twelveHourFormat = hours % 12 || 12;
      const timeFormat = amPM || (hours >= 12 ? 'PM' : 'AM');
      return `${twelveHourFormat}:${minutes.toString().padStart(2, '0')} ${timeFormat}`;
    };
    return `${formatTime(startTime)} - ${formatTime(endTime)}`;
  };

  // Navigate to Event details Page
  const handleCardClick = (eventId) => {
    navigate(`/event-details/${eventId}`);
  }

  return (
    <div className='featured-venues'>
      <div className='container'>
        <Titledesigen className='section-title position-relative'>
          <h2 className='ps-3 m-0'>Featured Venues</h2>
        </Titledesigen>
        <div className='venues-cards row'>
          <div className='venues-menu col-xxl-3 col-lg-4 col-md-6 col-sm-12 col-12'>
            <ul className='bg-light m-0'>
              {venuesData.map((venue) => (
                <li
                  key={venue.id}
                  className={`py-2 d-flex align-items-center features-tag ${activeVenueId === venue.id ? 'active' : ''}`}
                  onClick={() => handleVenueClick(venue.id)}
                >
                  <img className='venue-img pe-3' alt='VenueImage' src={venue.image} />
                  <span>{venue.name}</span>
                </li>
              ))}
            </ul>
          </div>

          {loading ? <div style={containerStyle}><Loading /></div> : (
            <div className='col-xxl-9 col-lg-8 col-md-6 col-sm-12 col-12'>
              <div className='row'>
                {selectedVenueData && Array.isArray(selectedVenueData) && selectedVenueData.length > 0 ? (
                  selectedVenueData.map((venue) => (
                    <div key={venue.id} className='col-lg-6 col-md-12 col-sm-12'>
                      <div className='venue-date-time d-flex align-items-center' onClick={() => handleCardClick(venue.id)}>
                        <div className='venue-date pe-3'>
                          <span>
                            <h2>{formatDate(venue.event_date)}</h2>
                            {formatMonth(venue.event_date)}
                          </span>
                        </div>
                        <div className='time-description'>
                          <p className='venue-time mb-2 d-flex align-items-center'>
                            <img src={clock} alt='clock' className='me-2' />
                            {formatTimeRange(venue.start_time, venue.end_time)}
                          </p>
                          <p className='venue-details m-0'>{venue.title}</p> {/* Assuming title property holds the event title */}
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className='venue-p justify-content-center d-flex align-items-center' >No Events Found</div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
