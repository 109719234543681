import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import Header from '../../Header/Header';
import Footer from '../../Footer';
import '../../../assets/scss/EventDetails.scss';
import Loading from '../../Loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faChevronRight, faLocationDot, faClock, faWallet, faHeart } from '@fortawesome/free-solid-svg-icons';
import MapCoordinates from './MapCoordinates';
import ServerName from '../../../config';
import { Helmet } from 'react-helmet';


// Container Style 
const containerStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
};

/* ***************** Truncate Address***************/

function truncateAddress(address, maxLength) {
  if (address.length <= maxLength) {
    return address;
  }
  return `${address.slice(0, maxLength)} ...`;
}

/* ***************** Truncate Link***************/
function truncateLink(link, maxLength) {
  if (link.length <= maxLength) {
    return link;
  }
  const truncatedLink = link.substring(0, maxLength);
  return `${truncatedLink} ...`;
}

/* ***************** Formate Date***************/

function formatDate(date) {
  const options = { weekday: 'long', month: 'long', day: 'numeric' };
  return date.toLocaleDateString('en-US', options);
}


export default function EventDetails() {

  const { eventId } = useParams();
  const [eventDetailsData, setEventDetailsData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isFavourite, setIsFavourite] = useState(false);

  const token = localStorage.getItem('token');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  /* ************  handleFavourite {Add_Favourite & Remove Favourite} Api Call ************/

  const handleFavourite = async () => {
    try {
      setIsFavourite(!isFavourite);
      const endpoint = isFavourite ? 'remove_favourite' : 'add_favourite';
      const response = await axios.post(
        `${ServerName}/${endpoint}`,
        { event_id: eventId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
        );
    } catch (error) {
      console.error('Error toggling favorite:', error);
    }
  };


  /* ***************** fetchEventDetails Api Call***************/

  useEffect(() => {
    const fetchEventDetails = async () => {
      try {
        const response = await axios.get(ServerName + '/event_detail', {
          params: {
            event_id: eventId,
          },
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          }
        });
        setEventDetailsData(response.data.data);
        setIsFavourite(response.data.data.is_favourite);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };

    if (eventId) {
      fetchEventDetails();
    }
  }, [eventId]);


  /******************Loading when No Events ***************/
  if (!eventDetailsData) {
    return (
      <div style={containerStyle}>
        <Loading />
      </div>
    );
  }


  const formatTimeRange = (startTime, endTime) => {
    if (startTime === "00:00:00" && endTime === "00:00:00") {
      // return <div className='d-flex justify-content-center align-item-center w-100'><span>-</span></div>;
      return "-"
    }
      const formatTime = (timeString) => {
        const [time, amPM] = timeString.split(' ');
        const [hours, minutes] = time.split(':').map(Number);
        const twelveHourFormat = hours % 12 || 12;
        const timeFormat = amPM || (hours >= 12 ? 'PM' : 'AM');
        return `${twelveHourFormat}:${minutes.toString().padStart(2, '0')} ${timeFormat}`;
      };
      return `${formatTime(startTime)} - ${formatTime(endTime)}`;
};


  const EventAddress = truncateAddress(eventDetailsData?.venue_details?.address, 30); // EventAdress truncate size of 30 words

  // const maxLinkLength = 30;
  // const EventLink = truncateLink(eventDetailsData?.link, maxLinkLength);
  const maxLinkLength = 30;
  const EventLink = eventDetailsData?.link ? truncateLink(eventDetailsData.link, maxLinkLength) : '-';

  const formattedEventDate = formatDate(new Date(eventDetailsData.event_date));

  //Google Api based on latitude & longitude

  const { latitude, longitude } = eventDetailsData.venue_details;

  const src = `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`;


  const paragraphDesc = eventDetailsData.description.split('<p></p>');
  
  const stripHtmlTags = (html, charLimit = 150) => {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    const textContent = doc.body.textContent || "";
    const limitedText = textContent.slice(0, charLimit);
    return limitedText;
};

const plainTextDescription = stripHtmlTags(paragraphDesc, 150);

  
  return (
    <div>
      <Helmet>
        <title>{eventDetailsData.title}</title>
        <meta name='title' content={eventDetailsData.title} />
        <meta name='description' content={plainTextDescription} />
        <meta name='keyword' content={eventDetailsData.title} />
        <meta property='og:url' content={`${ServerName}/event-details/${eventId}`}/>
        <meta property='og:title' content={eventDetailsData.title} />
        <meta property='og:description' content={plainTextDescription} />
        <meta property='og:image' content={eventDetailsData.image} />
        <link rel="canonical" href={`${ServerName}/event-details/${eventId}`} />
      </Helmet>
      <Header />
      <section className='eventdetails'>
        <div className='container'>
          <div className='event-information'>
            <div className='event-img'>
              <div className='event-details row'>
                <div className=' col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 position-relative'>
                  <div className='pop-days position-absolute text-white d-flex align-items-center text-center'>
                    <span className=''>
                      {Math.ceil((new Date(eventDetailsData.event_date.split(" ")[0]) - new Date()) / (1000 * 60 * 60 * 24)) <= 0
                        ? 'Today'
                        : Math.ceil((new Date(eventDetailsData.event_date.split(" ")[0]) - new Date()) / (1000 * 60 * 60 * 24)) === 1
                          ? '1 day left'
                          : `${Math.ceil((new Date(eventDetailsData.event_date.split(" ")[0]) - new Date()) / (1000 * 60 * 60 * 24))} days left`}
                    </span>

                  </div>
                  {token && (
                    <div className='like-btn position-absolute' onClick={handleFavourite}>
                      <span>  <a >
                        <FontAwesomeIcon
                          icon={faHeart}
                          style={{ color: isFavourite ? "#ED3535" : "#ffffff" }}
                        />
                      </a>
                      </span>
                    </div>
                  )}

                  <img src={eventDetailsData.large_image} alt='eventDetailsImage' className='event-banner ' />
                </div>
                <div className='col-xl-4 col-xxl-4 col-lg-4 col-md-12 col-sm-12  text-white'>
                  <div className='more-info-links p-4'>
                      {eventDetailsData.link ? (
                        <a href={eventDetailsData.link} target='_blank' className='text-decoration-none'>
                          <button className='info-btn btn d-flex justify-content-center mx-auto text-white align-items-center gap-2 w-100 fs-5'>
                            <FontAwesomeIcon icon={faInfoCircle} />  MORE INFO
                          </button> 
                        </a>
                      ) : (
                        <button className='info-btn btn d-flex justify-content-center mx-auto text-white align-items-center gap-2 w-100 fs-5' disabled>
                          <FontAwesomeIcon icon={faInfoCircle} />  MORE INFO
                        </button>
                      )}

                    <div className='d-flex flex-lg-column justify-content-between row'>

                      <Link to={src} target='_blank' className='links link-details d-flex align-items-center gap-1 border-bottom py-2 pb-3 text-decoration-none'>
                        <a >
                          <FontAwesomeIcon icon={faLocationDot} style={{ color: "#ffffff", }} className='event-info-icon me-3 fs-3 mt-2' />
                        </a>
                        <div >
                          <a className='text-decoration-none text-white pb-2'>
                            <h5 className="pt-2">{eventDetailsData.location}</h5>
                            {EventAddress}, {eventDetailsData.venue_details.city}, {eventDetailsData.venue_details.state}, {eventDetailsData.venue_details.zip_code}
                          </a>
                        </div>
                        <a className='ms-auto text-white'>
                          < FontAwesomeIcon icon={faChevronRight} className="ms-auto fs-3 pe-3" />
                        </a>
                      </Link>

                      <div className='links  d-flex align-items-center gap-1 border-bottom py-2 pb-3'>
                        <FontAwesomeIcon icon={faClock} style={{ color: "#ffffff", }} className='event-info-icon me-3 fs-3 mt-2' />
                        <div>
                          <h5 className='pt-2'>{formattedEventDate}</h5>
                           <a className="text-decoration-none text-white pb-2">
                            {formatTimeRange(eventDetailsData.start_time, eventDetailsData.end_time)}
                          </a>
                        </div>
                      </div>   
                      {eventDetailsData.link ? (
                      <Link to={eventDetailsData.link} target='_blank' className='links link-details  d-flex align-items-center gap-1 py-2 text-decoration-none'>
                        <a> <FontAwesomeIcon icon={faWallet} style={{ color: "#ffffff", }} className='event-info-icon me-3 fs-3 mt-2' /> </a>
                        <div>
                          <a className='text-decoration-none text-white pb-2'>
                            <h5 className="pt-2">{eventDetailsData.type}</h5>
                            {EventLink && <span>{EventLink}</span>}
                          </a>
                        </div>
                        {/* {EventLink &&   */}
                        <a className='ms-auto text-white'>
                          <FontAwesomeIcon icon={faChevronRight} className='ms-auto fs-3 pe-3' />
                        </a>
                        {/* } */}
                      </Link>
                    ) : (
                      <div className='links link-details  d-flex align-items-center gap-1 py-2 text-decoration-none'>
                        <a> <FontAwesomeIcon icon={faWallet} style={{ color: "#ffffff", }} className='event-info-icon me-3 fs-3 mt-2' /> </a>
                        <div>
                          <a className='text-decoration-none text-white pb-2'>
                            <h5 className="pt-2">{eventDetailsData.type}</h5>
                            {EventLink && <span>{EventLink}</span>}
                          </a>
                        </div>
                        {/* {EventLink &&   */}
                        <a className='ms-auto text-white'>
                          <FontAwesomeIcon icon={faChevronRight} className='ms-auto fs-3 pe-3' />
                        </a>
                        {/* } */}
                      </div>
                    )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="event-title pb-5">
              <h2>{eventDetailsData.title}</h2>
            </div>
            {paragraphDesc.map((paragraph, index) => (
              <p className='event-description col-12' key={index} dangerouslySetInnerHTML={{ __html: paragraph }}></p>
            ))}
            {/* {renderedParagraphs} */}
          </div>
          <div className='d-flex align-items-center justify-content-center gap-5'></div>
        </div>
      </section>
      <div className='event-map'>
        <MapCoordinates
          latitude={eventDetailsData.venue_details.latitude}
          longitude={eventDetailsData.venue_details.longitude}
        />
      </div>
      <Footer />
    </div>
  );
}
