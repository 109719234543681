import React, { useEffect } from 'react';
import facebook from '../.././/../assets/img/login-facebook.svg';
// import { configureFacebookSDK } from './FacebookConfig';
import { useNavigate } from "react-router-dom";
import ServerName from '../../../config';



/* *********************** Facebook SignIn or SignUp **********************/

const FacebookSignIn = () => {
  const navigate = useNavigate();
  const handleFacebookLogin = async () => {
    try {
      const response = await new Promise((resolve, reject) => {
        window.FB.login(
          function (response) {
            if (response.authResponse) {
              resolve(response.authResponse);
            } else {
              console.log(" auth reject")
              reject(new Error('User denied Facebook login.'));
            }
          },
          { scope: 'email' }
        );
      });

      console.log('Facebook Login Response:', response.accessToken);
      fetchUserInfo(response.accessToken);
      const userData = {
        email: response.email,
        name: response.name,
        user_type: 'facebook'
      };
      sendUserDataToBackend(userData)  
      // Handle authentication and user data here
    } catch (error) {
      console.error('Facebook Login Error:', error);
      // Handle error
    }
  };

  /* *********************** Get Facebook User Data **********************/

  const fetchUserInfo = async (accessToken) => {
    try {
      const userInfoResponse = await fetch(`https://graph.facebook.com/me?fields=name,email&access_token=${accessToken}`);
      const userInfo = await userInfoResponse.json();

      console.log('Facebook User Info:', userInfo.email);

      const userData = {
        email: userInfo.email || '',
        name: userInfo.name || '',
        user_type: 'facebook'
      };
      sendUserDataToBackend(userData);
    } catch (error) {
      console.error('Error fetching user info:', error);
    }
  };


  // Sending User Data to Backend 
  const sendUserDataToBackend = async (userData) => {
    console.log("Data from facebook before sending backend Api :", userData);
    try {
      const response = await fetch(ServerName + '/social_login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(userData),
      });

      const responseData = await response.json();
      console.log("Data After Sending Backend API:", responseData);
      localStorage.setItem('token', responseData.data.token);
      navigate('/');
    } catch (error) {
      console.error('Backend API error:', error);
    }
  };

  if (window.FB === undefined) {
    window.fbAsyncInit = function () {
      initialize();
    };
  }
  else {
    initialize();
  }

  function initialize() {
    window.FB.init({
      appId: '619806210060864', //Facebook App Id 
      cookie: true,
      xfbml: true,
      version: 'v17.0'
    });
  }

  return (
    <div>
      <a className='text-decoration-none py-3 px-4 border rounded-1' onClick={handleFacebookLogin} ><img src={facebook} alt='facebook' /></a>
    </div>
  );
};

export default FacebookSignIn;
