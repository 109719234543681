import axios from "axios";
import ServerName from "../../config";
// Handle Error
const handleApiError = (error) => {
  console.error('Error fetching data:', error);
  throw error;
};

/******************  FetchAllEvents  Api Call ***************/
export const fetchByCategories = async () => {
  try {
    const response = await axios.get(ServerName+'/get_categories_list');
    return response.data.data;
  } catch (error) {
    handleApiError(error);
  }
};

/*****************  fetchByCategories  Api Call ***************/
export const fetchAllEvents = async () => {
  try {
    const response = await axios.get(ServerName+'/get_home_page_events_list');
    return response.data.data;
  } catch (error) {
    handleApiError(error);
  }
};

/*********** Events FetchByDate  *********/
export const FetchByDate = async (selectedDate, selectedCategories, locationId) => {
  try {
    const response = await axios.get(ServerName+'/get_home_page_events_list', {
      params: {
        filter_by_date: selectedDate,
        category_id: selectedCategories.includes('all') ? '' : selectedCategories.join(','),
        location_id: locationId
      },
    });
    return response.data.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const handleDateChange = async (selectedDate, setSelectedFilter, selectedCategories, setEvents, setSelectedDate, setLoading,locationId) => {
  try {
    setLoading(true);
    setSelectedFilter('');
    const data = await FetchByDate(selectedDate, selectedCategories,locationId);
    setEvents(data);
    setSelectedDate(selectedDate);
  } catch (error) {
    handleApiError(error);
  } finally {
    setLoading(false);
  }
};


