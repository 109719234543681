import React from 'react'
import RouteManager from './router/route-manager'
import { BrowserRouter as Router } from 'react-router-dom';

export default function App() {
  return (
    <>
      <RouteManager/>
    </>
  )
}


