import React, { useEffect, useState } from 'react'
import '../assets/scss/Favourite.scss';
import Header from './Header/Header'
import Footer from './Footer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faHeart } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import Loading from './Loading';
import noevent from '../assets/img/NoEvent.png';
import axios from 'axios';
import { SmallCardAfter, containerStyle } from './ContainerStyle';
import { useAuth } from './Header/AuthContext';
import ServerName from '../config';
import { Helmet } from 'react-helmet';
function Favourite() {
  const { setIsLoggedIn } = useAuth();
    const [favoriteEvents, setFavoriteEvents] = useState([]);
    const [loading, setLoading] = useState(true);
    // const [apiError, setApiError] = useState(false);

    const token = localStorage.getItem('token');
    const navigate = useNavigate();

    /* ***************** Remove favourtite Api Call ***************/

    const handleFavourite = async (eventId) => {
        try {
            await axios.post(
                ServerName+'/remove_favourite',
                { event_id: eventId },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            setFavoriteEvents(prevFavoriteEvents =>
                prevFavoriteEvents.filter(event => event.id !== eventId)
            );
        } catch (error) {
            console.error('Error removing from favorite:', error);
        }
    };


    //Navigate event Details Page
    
    useEffect(() => {
        const headers = new Headers();
        headers.append('Authorization', `Bearer ${token}`);
        
        fetch( ServerName+'/get_my_favourite_event_list', {
            method: 'GET',
            headers: headers
        })
        .then(response => {
            if (response.status === 401) {
                // Unauthorized, clear token and navigate to the home page
                localStorage.removeItem('token');
                setIsLoggedIn(false);
                navigate('/');
                return Promise.reject('Unauthorized');
            }
            return response.json();
        })
        .then(data => {
            setFavoriteEvents(data.data);
            setLoading(false);
        })
        .catch(error => {
            if (error !== 'Unauthorized') {
             
                console.error('Error:', error);
               
            }
            
            setLoading(false);
        });
    }, []);
    
    
    const handleEventClick = (eventId) => {
        navigate(`/event-details/${eventId}`);
    };

    return (
        <>
            <Helmet>
            <title>Favorites Page</title>
            <meta name="title" content="Favorites page"/>
            <meta name="description" content='Get all info of Favorites events, user fav, favorite events'/>
            <meta name="keyword" content="Favorites events, fav events, likes events"/>
            <meta property='og:url' content={`${ServerName}/favorite`}/>
            <meta property='og:title' content="Favorites page" />
            <meta property='og:description' content="Get all info of Favorites events, user fav, favorite events" />
            <meta property='og:image' content={`${ServerName}/logo192.png`}/>
            <link rel="canonical" href={`${ServerName}/favorite`} />
            </Helmet>
            <Header />
            <section>
                <div className='container'>
                    <div className='pt-5 '>
                        <SmallCardAfter className='section-title position-relative col-xxl-5 col-xl-12 pb-5'>
                            <h2 className='ps-3'>Favorites</h2>
                        </SmallCardAfter>
                    </div>
                    {loading ? (
                        <div style={containerStyle}><Loading /></div>
                    ) : (
                        <div className='favourite-events row '>
                            {favoriteEvents.length === 0 ? (
                                <div className='no-event d-flex align-items-center justify-content-center py-5 my-5 flex-column'>
                                    <div className='no-event-img'>
                                        <img src={noevent} alt='no event' />
                                    </div>
                                    <div className='mt-4 text-center '>
                                        <h1>No Favorites Found!</h1>
                                        <p> You haven't added anything in favorite yet.</p>
                                    </div>
                                </div>
                            ) : (
                                favoriteEvents.map(event => (
                                    <div key={event.id} className='col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 p-3 position-relative'>
                                        <div
                                            className='like-btn position-absolute'
                                            onClick={() => handleFavourite(event.id)}
                                        >
                                            <span>
                                                <a>
                                                    <FontAwesomeIcon
                                                        icon={faHeart}
                                                        style={{ color: '#ED3535' }}
                                                    />
                                                </a>
                                            </span>

                                        </div>
                                        <div className='favorite-pop-days position-absolute text-white d-flex align-items-center text-center'> 
                                            <span className=''>
                                                {Math.ceil((new Date(event.event_date.split(" ")[0]) - new Date()) / (1000 * 60 * 60 * 24)) <= 0
                                                ? 'Today'
                                                : Math.ceil((new Date(event.event_date.split(" ")[0]) - new Date()) / (1000 * 60 * 60 * 24)) === 1
                                                ? '1 day left'
                                                : `${Math.ceil((new Date(event.event_date.split(" ")[0]) - new Date()) / (1000 * 60 * 60 * 24))} days left`}
                                            </span>
                                        </div>
                                        <div className='favourite-event bg-white' onClick={() => handleEventClick(event.id)}>
                                            <div className='favourite-img'>
                                                <img src={event.image} className='w-100' alt='Favourite Image' />
                                            </div>
                                            <div className='fav-event-details'>
                                                <a className='d-flex align-items-top text-decoration-none text-black' href="#">
                                                    <FontAwesomeIcon icon={faMapMarkerAlt} className='post-location-icon pe-2' />
                                                    <span>{event.location}</span>
                                                </a>
                                                <p className='post-description pe-2 mb-0'>
                                                    <a className='text-decoration-none' href="#">{event.title}</a>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            )}
                        </div>
                    )}
                </div>
            </section>
            <Footer />
        </>
    )
}

export default Favourite
