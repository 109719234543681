import React, { useState, useEffect } from "react";
import Header from "./Header/Header";
import Footer from "./Footer";
import "../assets/scss/ContactUs.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faTwitter,
  faLinkedin,
  faInstagram,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import contact from "../assets/img/contact.png";
import ServerName from "../config";
import { Helmet } from "react-helmet";
function ContactUs() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [Phone_Number, setPhone_Number] = useState("");
  const [Subject, setSubject] = useState("");
  const [Messege, setMessege] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [emailError, setEmailError] = useState(true);

  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Regex for Email

  /* ***********************  Contact-Us  Api Call **********************/

  const handleSubmit = async (e) => {
    e.preventDefault();

    // When user submit without fill any input fields
    if (
      name === "" ||
      email === "" ||
      Phone_Number === "" ||
      Subject === "" ||
      Messege === ""
    ) {
      setFormSubmitted(true);
      return;
    }
    if (!emailRegex.test(email)) {
      setFormSubmitted(true);
      setEmailError("Enter a valid Email Address");
      return;
    }

    setEmailError("");
    // Sending (items) Data to backend After Submitting ContactUs Form
    const items = {
      name: name,
      email_address: email,
      phone_number: Phone_Number,
      subject: Subject,
      message: Messege,
    };

    try {
      const response = await axios.post(ServerName + "/web_contact_us", items);
      if (response.data.success) {
        const token = response.data.token;
        axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        toast.success("Thank you! Mail sent successfully..", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
      } else {
        toast.error(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
      }
      setEmail(""); // clear input field when user submit form
      setName("");
      setMessege("");
      setPhone_Number("");
      setSubject("");
      setFormSubmitted(false);
    } catch (error) {
      toast.error("An error occurred while registering.", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
    }
  };

  return (
    <>
      <Helmet>
        <title>Contact-us Page</title>
        <meta name="title" content="Contact-us page" />
        <meta name="description" content="contact to admin" />
        <meta name="keyword" content="Contact-us , get info, events, venues" />
        <meta property="og:url" content={`${ServerName}/contact-us`} />
        <meta property="og:title" content="Contact-us page" />
        <meta
          property="og:description"
          content="Get all info of events, contact to admin"
        />
        <meta property="og:image" content={`${ServerName}/logo192.png`} />
        <link rel="canonical" href={`${ServerName}/contact-us`} />
      </Helmet>
      <Header />
      <section className="contact-us-section">
        <div className="container">
          <div className="contact-us ">
            <div className=" py-4">
              <h1 className="contactus-title">Contact Us</h1>
            </div>
            <div className="sign d-flex w-100">
              {/* <div className='location-map'>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d877.9656964579636!2d-0.12811172204951815!3d51.508409313227276!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47d8a00baf21de75%3A0x52963a5addd52a99!2sLondon%2C%20UK!5e0!3m2!1sen!2sin!4v1692859287801!5m2!1sen!2sin"
                width="100%"
                height="500"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div> */}
              <div className="text-center sign-left d-flex flex-column align-items-center justify-content-center ">
                <div className="login-logo">
                  <img src={contact} alt="contact" />
                </div>
              </div>
              <div className="bg-white sign-left">
                <div className="text-center">
                  <h4 className="mt-4 mb-3 pb-1">Contact Us</h4>
                </div>
                <form onSubmit={handleSubmit} className="py-3 contact-form">
                  <div className="contact-email">
                    <label htmlFor="text">Enter your name :</label>
                    <input
                      type="text"
                      name="name"
                      placeholder="Enter your name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                    {formSubmitted && name === "" && (
                      <span className="error-message">Enter Name</span>
                    )}
                  </div>
                  <div className="contact-email">
                    <label htmlFor="email">Email :</label>
                    <input
                      type="email"
                      name="email"
                      placeholder="example@gmail.com"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    {formSubmitted && email != "" && emailError && (
                      <span className="error-message">{emailError}</span>
                    )}
                    {formSubmitted && email === "" && (
                      <span className="error-message">
                        Enter a valid Email Address
                      </span>
                    )}
                  </div>
                  <div className="contact-email">
                    <label htmlFor="email">Subject : </label>
                    <input
                      type="text"
                      name="subject"
                      placeholder="Your subject"
                      value={Subject}
                      onChange={(e) => setSubject(e.target.value)}
                    />
                    {formSubmitted && Subject === "" && (
                      <span className="error-message">Enter Subject</span>
                    )}
                  </div>
                  <div className="contact-email">
                    <label htmlFor="email">Phone Number : </label>
                    <input
                      type="tel"
                      name="phone_number"
                      pattern="[0-9]*"
                      placeholder=""
                      value={Phone_Number}
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        if (/^[0-9]*$/.test(inputValue)) {
                          setPhone_Number(inputValue);
                        }
                      }}
                    />
                    {formSubmitted && Phone_Number === "" && (
                      <span className="error-message">
                        Enter Valid PhoneNumber
                      </span>
                    )}
                  </div>
                  <div className="contact-email d-flex flex-column ">
                    <label htmlFor="align-item-top">Message : </label>
                    <textarea
                      rows="3"
                      name="messege"
                      placeholder=""
                      value={Messege}
                      onChange={(e) => setMessege(e.target.value)}
                    />
                    {formSubmitted && Messege === "" && (
                      <span className="error-message">Enter Message</span>
                    )}
                  </div>
                  <button className="contact-btn" type="submit">
                    Send
                    <ToastContainer />
                  </button>
                </form>
              </div>
            </div>
            <div className="contact-info row text-white">
              <div className="col-lg-3 col-md-6 col-12 address">
                <span className="">ADDRESS</span>
                <h5 className="pt-3">225 Edgware Rd, London W2 1JU, UK</h5>
              </div>
              <div className="col-lg-3 col-md-6 col-12 phone">
                <span className="">PHONE</span>
                <h5 className="pt-3">+1 800 559 6580</h5>
              </div>
              <div className="col-lg-3 col-md-6 col-12 email">
                <span className="">EMAIL</span>
                <h5 className="pt-3">info@companyname.com</h5>
              </div>
              <div className="col-lg-3 col-md-6 col-12 stay-connected">
                <span className="">STAY CONNECTED</span>
                <h5 className="pt-3 d-flex gap-3">
                  <FontAwesomeIcon icon={faFacebook} />
                  <FontAwesomeIcon icon={faTwitter} />
                  <FontAwesomeIcon icon={faLinkedin} />
                  <FontAwesomeIcon icon={faInstagram} />
                  <FontAwesomeIcon icon={faYoutube} />
                </h5>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default ContactUs;
