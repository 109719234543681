import React from 'react';
const MapCoordinates = ({ latitude, longitude  }) => {
    const zoom = 20;
    const mapUrl = `https://maps.google.com/maps?q=${latitude},${longitude}&z=${zoom}&output=embed`
    return (
        <div className='event-map'>
             <iframe
                src={mapUrl}
                width="100%"
                height="400"
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                title="google map"
            >
            </iframe>

        </div>
    );
};

export default MapCoordinates;
