import React, { useState } from 'react';
import Header from '../Header/Header';
import Footer from '../Footer';
import logo from '../../assets/img/logo192.png';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import ServerName from '../../config'
import { Helmet } from 'react-helmet';
function ChangePassword() {

  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [submitted, setSubmitted] = useState(false);

  const [error, setError] = useState(null);

  const navigate = useNavigate();
  const location = useLocation();
  const userEmail = location.state && location.state.email;
  const otpValue = location.state && location.state.otp;


  /* ***********************Passowrd Chnages Api Call**********************/
  const handleChangePassword = async (e) => {
    e.preventDefault();
    setSubmitted(true);
    const requestData = {
      email: userEmail,
      otp: otpValue,
      new_password: newPassword,
      confirm_new_password: confirmPassword,
    };
    try {
      const response = await axios.post(ServerName + '/verify_otp_change_password', requestData);
      if (response.data.success) {
        toast.success('Password change successfully.', {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        await new Promise((resolve) => setTimeout(resolve, 2000)); // Wait 2 second for Taoster Message
        navigate('/sign-in');  // Navigate to Sign-Page
      } else {
        toast.error('Validation errors', {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });

      }
    } catch (error) {
      setError('An error occurred while changing the password. Please try again later.');
    }
  };

  return (
    <>
      <Helmet>
        <title>Change Passowrd</title>
        <meta content='Change Passowrd' />
        <meta name="keyword" content="Change Passowrd" />
      </Helmet>
      <Header />
      <div className='mx-auto'>
        <div className='sign d-flex w-100'>
          <div className='text-center sign-left forgot-pass d-flex flex-column align-items-center justify-content-center'>
            <h3 className='login-title mt-5'> Hey There! Welcome 405 </h3>
            <div className='login-logo'>
              <img src={logo} alt='Logo' />
            </div>
          </div>
          <div className='bg-white sign-right forgot-pass'>
            <div className='sign-form animated-form'>
              <div className='text-center'>
                <h4 className='mt-5 mb-3 pb-1'>Reset Password </h4>
              </div>
              <form>
                <div className='sign-email'>
                  <label htmlFor='newPassword'>Enter your New Password: </label>
                  <input
                    type='password'
                    name='newPassword'
                    placeholder=''
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)} required />
                </div>
                <div className='sign-email'>
                  <label htmlFor='confirmPassword'>Confirm your New Password: </label>
                  <input
                    type='password'
                    name='confirmPassword'
                    placeholder=''
                    value={confirmPassword}
                    onChange={(e) =>
                      setConfirmPassword(e.target.value)} required />
                  {newPassword !== confirmPassword && submitted && (
                    <span className='text-danger' style={{ fontSize: '16px' }}>
                      New Password does not match Confirm Password
                    </span>
                  )}
                </div>
                <button
                  className='sign-btn'
                  type='submit'
                  onClick={handleChangePassword}>
                  Change Password <ToastContainer />
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default ChangePassword;
