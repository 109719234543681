import React, { useState, useEffect } from 'react';
import '../../../assets/scss/Event.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../../../assets/scss/_varibles.scss';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Footer from '../../Footer';
import Header from '../../Header/Header';
import { handleDateChange, fetchAllEvents, fetchByCategories } from '../ApiUtil'; // Import the functions
import EventComponent from '../EventComponent'; // Import the EventComponent
import ServerName from '../../../config';
import { Helmet } from 'react-helmet';

export default function Event() {

  const [events, setEvents] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState('');
  const [loading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [showAllEvents, setShowAllEvents] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState(['all']);
  const [error, setError] = useState(null);
  const [options, setOptions] = useState([]);
  const [locationId, setLocationId] = useState('');
  const [locationName, setLocationName] = useState('');
  const [initialRender, setInitialRender] = useState(true); 
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const [categoriesData, eventsData] = await Promise.all([fetchByCategories(), fetchAllEvents()]);
        setShowAllEvents(true);
        setCategories(categoriesData);
        setEvents(eventsData);
        setLoading(false);
      } catch (error) {
        setError('Error fetching data. Please try again later.');
        setLoading(false);
      }
    };
    fetchData();
  }, []);


  // const onDateChange = (event) => {
  //   const selectedDate = event.target.value;
  //   handleDateChange(selectedDate, setSelectedFilter, selectedCategories, setEvents, setSelectedDate, setLoading);
  // };
  const handleLocationChange = async (event) => {
    const { value } = event.target;
    if (value) {
      setLocationId(value);
      } else {
        setLocationId('')
      }
    };

    // useEffect(()=>{
    //   if (locationId !== undefined && locationId !== null) {
    //     if(selectedCategories == 'all'){
    //       handleFilterChange(selectedFilter,'all');
    //     }else{
    //       handleFilterChange(selectedFilter);
    //     }
    //   }
    // },[locationId]);
    
    useEffect(() => {
      setInitialRender(false);
  }, [initialRender]);

  useEffect(() => {
    if (!initialRender) { // Check if it's not the initial render
      if (locationId !== undefined && locationId !== null) {
        if (selectedCategories == 'all') {
          handleFilterChange(selectedFilter, 'all');
        } else {
          handleFilterChange(selectedFilter);
        }
      }
    } 
  }, [locationId]);

  const onDateChange = (date) => {
    if (date) {
      let selectedDate = date;
      if (!(date instanceof Date)) {
        // If date is not a Date object, try creating one
        selectedDate = new Date(date);
      }
      if (isNaN(selectedDate.getTime())) {
        // Handle the case when the date is invalid
        console.error('Invalid date:', date);
        return;
      }
      const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
      const formattedDate = selectedDate.toLocaleDateString('en-US', options).replace(/\//g, '-');
      handleDateChange(formattedDate, setSelectedFilter, selectedCategories, setEvents, setSelectedDate, setLoading, locationId);
    } else {
      // Handle the case when the date is cleared
      handleDateChange('', setSelectedFilter, selectedCategories, setEvents, setSelectedDate, setLoading,locationId);
    }
  };


  const toggleFilter = (filter) => {
    if (selectedFilter === filter) {
      handleFilterChange(null);
    } else {
      handleFilterChange(filter);
    }
  };

  const handleFilterChange = async (filter, category_id) => {
    setLoading(true);
    try {
      let updatedSelectedCategories = [...selectedCategories];
      if (category_id === 'all') {
        updatedSelectedCategories = ['all'];
      } else {
        if (selectedCategories.includes('all') && selectedFilter === filter) {
          updatedSelectedCategories = [category_id];
        } else {
          if (updatedSelectedCategories.includes(category_id)) {
            updatedSelectedCategories = updatedSelectedCategories.filter((id) => id !== category_id);
          } else {
            if (category_id) {
              updatedSelectedCategories.push(category_id);
            }
          }
        }
      }

      if (['today', 'week', 'weekend', 'month'].includes(filter)) {
        const dateInput = document.getElementById('dateInput');
        if (dateInput) {
          dateInput.value = '';
        }
        setSelectedDate(null);
      }

      setSelectedCategories(updatedSelectedCategories);

      if (updatedSelectedCategories.length === 0) {
        setSelectedCategories(['all']);
      }

      const categoryQueryString = updatedSelectedCategories.includes('all') ? '' : updatedSelectedCategories.join(',');

      const selectedDateForParams = selectedFilter === filter ? selectedDate : null;

      const endpoint = ServerName + '/get_home_page_events_list';

      const params = {
        filter_by_date: selectedDateForParams,
        category_id: categoryQueryString,
        filter_by_day: filter,
        location_id: locationId
      };

      const response = await axios.get(endpoint, { params });
      setEvents(response.data.data);
      setSelectedFilter(filter);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    setSelectedCategories(['all']);
    // handleFilterChange(selectedFilter, 'all');
    window.scrollTo(0, 0);
  }, []);

  const handleEventClick = (eventId) => {
    navigate(`/event-details/${eventId}`);
  };
  
  /***********************fetchLocation htmlFor Select Dropdown*****************************/
  const fetchLocation = async () => {
    try {
      const response = await axios.get(ServerName + '/get_filter_locations_list');
      const locationResponse = response.data.data;
      const formattedOptions = locationResponse.map((location) => ({
        value: location.id,
        label: `${location.name}`,
      }));
      setOptions([{ value: '', label: 'Select Location'}, ...formattedOptions]);
    } catch (error) {
      console.error('Error fetching locations:', error);
    }
  };

  useEffect(() => {
    fetchLocation();
  }, []);

  return (
    <>
      <Helmet>
        <title>Event Page</title>
        <meta name="title" content="Event page" />
        <meta name="description" content='Get all info of events, explore events' />
        <meta name="keyword" content="All events, search events, explore events, upcoming events," />
        <meta property="url" content={`${ServerName}/events`} />
        <meta property='og:title' content="Event page" />
        <meta property='og:description' content="Get all info of events, explore events" />
        <meta property='og:image' content={`${ServerName}/logo192.png`} />
        <link rel="canonical" href={`${ServerName}/events`} />
      </Helmet>
      <Header />
      <EventComponent
        onDateChange={onDateChange}
        selectedFilter={selectedFilter}
        toggleFilter={toggleFilter}
        showAllEvents={showAllEvents}
        selectedCategories={selectedCategories}
        handleFilterChange={handleFilterChange}
        loading={loading}
        events={events}
        handleEventClick={handleEventClick}
        categories={categories}
        setLocationId={setLocationId}
        setLocationName={setLocationName}
        options={options}
        locationId={locationId}
        handleLocationChange={handleLocationChange}
      />
      <Footer />
    </>
  );
}
