import React, { useState, useEffect } from 'react';
import '../../assets/scss/VenueDetails.scss'
import '../../assets/scss/_varibles.scss'
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';
import Loading from '../Loading'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { useNavigate, useParams } from 'react-router-dom';
import Footer from '../Footer';
import Header from '../Header/Header';
import ServerName from '../../config';
import { Helmet } from 'react-helmet';
import CreateEvent from '../../assets/img/create-events.svg';
// import {containerStyle } from '../ContainerStyle';

// Container Style 
const containerStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
};

export default function Event() {

  const { venueId } = useParams();
  const [VenueDetailsData, setVenueDetailsData] = useState(null);
  const [venueDetails , setvenueDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();


  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, []);

/* **********************fetchVenueDetails events_by_venue & venue_id ***********************/
  useEffect(() => {
    const fetchVenueDetails = async () => {
      try {
        if (venueId) {
          // Fetch venue details
          const venueDetailsResponse = await axios.get(
            ServerName + `/get_venue_detail?venue_id=${venueId}`
          );
          setvenueDetails(venueDetailsResponse.data.data);
          console.log(venueDetailsResponse.data)
          
          // Fetch events by venue
          const eventsResponse = await axios.get(
            ServerName + `/get_events_by_venue?venue_id=${venueId}`
          );
          setVenueDetailsData(eventsResponse.data.data);
          
          setLoading(false);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };
  
    fetchVenueDetails();
  }, [venueId]);
  

  

//Naviagate to Event Details Page
  const handleEventClick = (eventId) => {
    navigate(`/event-details/${eventId}`);
  };

  
  const handleCreatorClick = () => {
    navigate('/creator');
  };

  if (!VenueDetailsData) {
    return (
      <div style={containerStyle}>
        <Loading />
      </div>
    );
  }
console.log(venueDetails)

  return (
    <div>
      <Helmet>
        <title>
          {venueDetails.name}
        </title>
        <meta name='title' content= {venueDetails.name}/>
        <meta name="description" content={venueDetails.address}/>
        <meta name='keyword' content={venueDetails.name} />
        <meta property='og:url' content={`${ServerName}/event-details/${venueId}`}/>
        <meta property='og:title' content={VenueDetailsData.name} />
        <meta property='og:description' content={venueDetails.address} />
        <meta property='og:image' content={venueDetails.image} />
        <link rel="canonical" href={`${ServerName}/event-details/${venueId}`} />
        </Helmet>
      <Header />
      
       <div className='venue-banner'>
        <div className='container'>
          <div className='venue-banner_slider'>
            {venueDetails && (
              <div className='venue-banner_slide d-flex'>
                <div className='venue-banner_details '>
                  <img src={venueDetails.large_image} alt="Venue" className='venue-banner_details-img'/>
                  <div className='position-absolute venue-bannre-location'>
                  <h1 className='venue-banner_title text-white'>{venueDetails.name}</h1>
                  <p className='venue-banner_text text-white'>
                  <FontAwesomeIcon icon={faMapMarkerAlt} className='post-location-icon pe-2' />{venueDetails.address}, {venueDetails.city}, {venueDetails.state}, {venueDetails.zip_code}</p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className='venue'>
        <div className='container'>
          <div className='venue-slider-navbar row'></div>
          <div className='venue-card'>
            {loading ? (
              <div style={containerStyle}>
                <Loading />
              </div>
            ) : (
              <div className='venue-post row'>
                {VenueDetailsData !== null && Array.isArray(VenueDetailsData) && VenueDetailsData.length > 0 ? (
                  VenueDetailsData.map((venue, index) => (
                    <div className='col-xxl-3 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 p-3' key={index}>
                      <div className='text-bg-light venue-post-details h-100' onClick={() => handleEventClick(venue.id)}>
                        <div className='venue-img'>
                          <img src={venue.image} className='w-100' alt="venueImage"/>
                        </div>
                        <div className='venue-information'>
                          <a className='location text-decoration-none d-flex'>
                          <FontAwesomeIcon icon={faMapMarkerAlt} className='post-location-icon pe-2' /> {venue.location}
                          </a>
                          <p className='post-description pe-2 mb-0'>
                            <a className='text-decoration-none'>
                              {venue.title}
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                  ))
                ) : ( 
                  <div className='col-12 text-center'>
                  <div className='no-event mb-5'>No Events Found</div>
                  <div className='' >
                    
                  </div>
                 
                  <div className=''>
                    <img src={CreateEvent} />
                  <div className='d-flex justify-content-center mt-4'>
                      <a className='eventbtn text-decoration-none' onClick={handleCreatorClick}> Create your own Events</a>
                  </div>
                  </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}